export const BLOCK_NUMBER_HEADERS = [
    {
        name: 'Blocked number',
        value: 'number',
        sortSettings: {
            key: '',
            sortBy: 'byNumber',
        },
        filterSettings: {},
        disabled: true,
    },
    {
        name: 'Campaign',
        value: 'campaign',
        sortSettings: {
            key: '',
            sortBy: 'byCampaign',
        },
        filterSettings: {},
    },
    {
        name: 'Actions',
        value: 'actions',
        width: 152,
        align: 'center',
        disabled: true,
    },
]
