import { useAuthorizationStore } from '@/store/authorizationStore'

function getFilterReporting() {
    const authorizationStore = useAuthorizationStore()

    const filter = localStorage.getItem('filterReporting')
    return filter ? JSON.parse(filter) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function setFilterReporting(filter) {
    localStorage.setItem('filterReporting', filter)
}

function removeFilterReporting() {
    localStorage.removeItem('filterReporting')
}

function getFilterReportingLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const filters = getFilterReporting()[authorizationStore.getWorkspaceSlug]

    if (filters && filters[type]) {
        return filters[type]
    }

    return {}
}

function setFilterReportingInLocalStorageByWorkspaceId(filter, type) {
    const authorizationStore = useAuthorizationStore()

    let activeFilter = getFilterReporting()

    if (activeFilter[authorizationStore.getWorkspaceSlug]) {
        activeFilter[authorizationStore.getWorkspaceSlug][type] = filter
    } else {
        activeFilter = {
            ...activeFilter,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: filter,
            },
        }
    }

    setFilterReporting(JSON.stringify(activeFilter))
}

export {
    getFilterReportingLocalStorageByWorkspaceId,
    setFilterReportingInLocalStorageByWorkspaceId,
    removeFilterReporting,
}
