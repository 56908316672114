<template>
    <div class="custom-scroll-y px-2 mx-0.5 max-h-screen-70">
        <div
            class="py-3.75 relative hover:bg-gray-80 hover:dark:bg-gray-770 hover:rounded-1.25 border-b-1 hover:transition-colors"
            :class="[
                'after:absolute after:h-5/6 after:w-1 after:top-1/2 after:-translate-y-1/2 after:-ml-3 after:rounded-r',
                wrapClass(index),
                !item.read_at && borderColorAfter(item),
                loading ? 'cursor-wait' : 'cursor-pointer',
            ]"
            v-for="(item, index) in notifications"
            :key="index"
            @mouseenter="onMouse(index)"
            @mouseleave="onMouse(-1)"
            @click="onRead(item, index)"
        >
            <div class="relative mb-1.25">
                <div class="mr-7.5 text-xs whitespace-pre-wrap text-gray-370 dark:text-white" v-html="messages(item)" />

                <div
                    v-if="!item.read_at"
                    class="w-6 h-6 flex items-center justify-center absolute top-0 right-0.5"
                    v-tippy="getTippyTable('Mark as read')"
                >
                    <div class="w-1.5 h-1.5 rounded-full bg-purple"/>
                </div>
            </div>

            <div class="text-xs text-gray-200 dark:text-gray-280">{{ date(item) }}</div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

import { formatDateForNotification } from '@/helpers/date-helper'
import { getTippyTable } from '@/helpers/tippy-helper'

import { NOTIFICATION_TYPES } from '@/constants/notificationTypes'

defineProps({
    notifications: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['onRead'])

const activeIndex = ref(-1)

function wrapClass(index) {
    const before = activeIndex.value - 1
    const after = activeIndex.value

    return index === before || index === after
        ? 'pl-5.5 pr-2.5 border-transparent'
        : 'mx-2.5 pl-3 border-gray-100 dark:border-gray-700'
}

function borderColorAfter({ data = {} }) {
    const { type } = data

    switch (type) {
    case NOTIFICATION_TYPES.warning:
        return 'after:bg-red'
    case NOTIFICATION_TYPES.information:
        return 'after:bg-blue-300'
    default:
        return ''
    }
}

function checkUrl(url) {
    return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(url)
}

function messages({ data = {} }) {
    const rows = data.message.split('\n')
    const results = rows.map((textRow) => {
        if (checkUrl(textRow)) {
            const res = textRow.split(' ').map((text) => {
                if (checkUrl(text)) {
                    return `<a target="_blank" href="${text}" class="text-purple dark:text-purple-140">${text}</a>`
                }
                return text
            })
            return res.join(' ')
        }
        return textRow
    })
    return results.join('\n')
}

function date({ created_at }) {
    return formatDateForNotification(created_at)
}

function onMouse(index) {
    activeIndex.value = index
}

async function onRead({ id, read_at }, index) {
    if (!read_at) {
        emits('onRead', id, index)
    }
}

</script>
