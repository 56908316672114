import { defineStore } from 'pinia'

import {
    getBlockedNumbers,
    unblockNumber,
    sendBlockedNumber,
    updateBlockedNumber,
    getBlockNumberFiltersAndSorting,
} from '@/api/block-numbers-routes'
import {
    addFilterInColumn,
    checkEqualsFilter,
    createFiltersForSend,
    createSortForSend,
    setFilter,
    setSorting,
    updateHeaderAfterSort,
    updateSortingAfterReset,
    updateSortHeaderColumn,
    updateSortingControl,
    getQuery,
    setQuery,
} from '@/helpers/filters-sorting-header-table-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import {
    getFilterOnPageInLocalStorageByWorkspaceId,
    getSortingInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId,
    setSortingInLocalStorageByWorkspaceId,
} from '@/helpers/save-sorting-local-storage-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'

import { BLOCK_NUMBER_HEADERS } from '@/constants/headersTable/blockNumberHeadersTable'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { SORT_RULES } from '@/constants/sortRules'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { FILTER_RULES } from '@/constants/filterRules'
import { removeFirstPlusForSearch } from '@/helpers/app-helper'
import { viewPorts } from '@/constants/viewPorts'

export const useBlockedNumbersStore = defineStore('blockedNumbers', {
    state: () => ({
        loading: false,
        actionLoading: false,
        allLoading: false,
        notNeedRequestChangePage: true,
        allBuyers: [],
        items: [],
        selected: [],
        page: 1,
        total: 1,
        onPage: 25,
        search: null,
        filters: [],
        sorting: [],
        selectedFilters: [],
        selectedSorting: [],
        filtersForSend: {},
        sortingForSend: {},
        activeColumnsTable: [],
        visibleHeadersTable: [],
    }),

    getters: {
        controlParams(state) {
            return {
                filters: state.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: state.sorting,
            }
        },
    },

    actions: {
        async goToLoadBlockNumberFiltersAndSorting() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getBlockNumberFiltersAndSorting()

            if (success) {
                this.goToSetBlockNumberFilters(payload)
                this.goToSetBlockNumberSorting(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        goToSetBlockNumberFilters({ filters }) {
            this.filters = filters
            this.updateFilterAfterQueryLocalStorage(this.filtersForSend)
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },

        goToSetBlockNumberSorting({ sorting }) {
            this.sorting = sorting
            this.updateSortingAfterQueryLocalStorage(this.sortingForSend)
        },

        async goToLoadBlockedNumbers() {
            const defaultStore = useDefaultStore()

            this.loading = true

            this.notNeedRequestChangePage = false

            const { success, payload, message } = await getBlockedNumbers({
                page: this.page,
                search: removeFirstPlusForSearch(this.search),
                onPage: this.onPage,
                ...this.filtersForSend,
                ...this.sortingForSend,
            })

            if (success) {
                const { data = [], current_page, last_page } = payload
                this.items = data
                this.page = current_page
                this.total = last_page

                this.checkValidPage()
            } else {
                this.loading = false
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToSendBlockedNumber(formData) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await sendBlockedNumber(formData)

            if (success) {
                this.goToLoadBlockedNumbers()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        checkValidPage() {
            if (this.page > this.total) {
                this.changePage(this.total)
            } else {
                this.loading = false
            }
        },

        async goToUnblockNumber(slugs) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await unblockNumber({ slugs })

            if (success) {
                this.clearSelected()
                this.goToLoadBlockedNumbers()
                this.checkValidPage()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToUpdateBlockedNumber({ slug, formData }) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, payload, message } = await updateBlockedNumber({
                slug,
                formData,
            })

            if (success) {
                this._changeBlockedNumbersAfterUpdate(payload)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        _changeBlockedNumbersAfterUpdate(number) {
            const index = this.items.findIndex((i) => i.slug === number.slug)

            if (index + 1) {
                this.items.splice(index, 1, number)
            }
        },

        onSearch(searchValue) {
            this.clearSelected()
            this.search = searchValue
            this.changePage(1)
        },

        sendFiltersSorting() {
            this.preparationFiltersForSend()
            this.preparationSortingForSend()
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        preparationFiltersForSend() {
            const newFilter = createFiltersForSend(this.selectedFilters)

            if (!checkEqualsFilter(newFilter, this.filtersForSend)) {
                this.filtersForSend = newFilter
            }
        },

        preparationSortingForSend() {
            this.sortingForSend = createSortForSend(this.selectedSorting, this.visibleHeadersTable)
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable, this.sortingForSend)
        },

        changePage(newPage) {
            this.page = newPage

            if (!this.notNeedRequestChangePage) {
                this.goToLoadBlockedNumbers()
            }
        },

        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.blockNumber)

            this.goToLoadBlockedNumbers()
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        clearSelected() {
            this.selected = []
        },

        updateSelectedFilters(payload) {
            this.clearSelected()
            this.selectedFilters = payload
            this.sendFiltersSorting()
        },

        updateSortingForSend(item, value) {
            this.sorting = updateSortingControl({ sortSettings: { sortBy: item.key } }, this.sorting, value)
        },

        createSelectedSorting(item, key) {
            const role = key ? SORT_RULES.find((r) => r.key === key) : SORT_RULES[0]
            this.selectedSorting = [...this.selectedSorting, { ...item, value: role }]
        },

        changeSelectedSortingAfterDelete(item) {
            this.selectedSorting = this.selectedSorting.filter((el) => el.key !== item.key)

            this.updateSortingForSend(item, false)
            this.sendFiltersSorting()
        },

        changeSelectedSortingAfterAdd(item) {
            this.createSelectedSorting(item)
            this.updateSortingForSend(item, true)
            this.sendFiltersSorting()
        },

        removeAllSorting() {
            this.selectedSorting = []
            this.sorting = updateSortingAfterReset(this.sorting)
        },

        resetSortFilter() {
            this.clearSelected()
            this.selectedFilters = []
            this.removeAllSorting()
            this.filtersForSend = null
            this.sortingForSend = {}
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable)
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        addSortingFromTable(sort) {
            const sortFilterTableStore = useSortFilterTableStore()

            this.selectedSorting = setSorting(sort, [...this.selectedSorting])
            this.visibleHeadersTable = updateSortHeaderColumn(sort, this.visibleHeadersTable)
            this.sorting = updateSortingControl(sort, this.sorting, true)
            this.sendFiltersSorting()
            sortFilterTableStore.openCloseTableSortMenu(true)
            sortFilterTableStore.openCloseSorting(true)
        },

        addFilterFromTable(filter) {
            const index = this.selectedFilters.findIndex((item) => item.key === filter.filterSettings.key)

            const sortFilterTableStore = useSortFilterTableStore()

            const { items, filterOpen } = setFilter(filter, [...this.selectedFilters])

            if (index === -1) {
                this.updateSelectedFilters(items)
            }

            sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.blockNumber, filterOpen)
            sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.blockNumber)
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.filters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.selectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.blockNumber, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.blockNumber)
            }
        },

        updateSortingAfterQueryLocalStorage(savedSort) {
            const sortFilterTableStore = useSortFilterTableStore()

            if (!Object.keys(savedSort).length) {
                return
            }

            Object.keys(savedSort).forEach((key) => {
                const findItem = this.sorting.find((el) => el.key === key)

                if (findItem) {
                    this.createSelectedSorting(findItem, savedSort[key])
                    this.updateSortingForSend(findItem, true)
                    sortFilterTableStore.openCloseSorting(true)
                }
            })

            this.addSortingToHeader()
        },

        addSortingToHeader() {
            this.selectedSorting.forEach((sort) => {
                const obj = {
                    sortSettings: {
                        sortBy: sort.key,
                    },
                    ...sort,
                }

                this.visibleHeadersTable = updateSortHeaderColumn(obj, this.visibleHeadersTable)
            })
        },

        saveFiltersSortingInLocalStorage() {
            setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.blockNumber)
            setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.blockNumber)
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTable = getActiveColumnsHeader(BLOCK_NUMBER_HEADERS, SETTINGS_HEADER_TABLE.blockNumber)
            this.setVisibleHeaders()
        },

        getLocalStorageFilter() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.blockNumber)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.filtersForSend = savedFilter
                this.saveQuery()
            }
        },

        getLocalStorageSorting() {
            const savedSort = getSortingInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.blockNumber)

            if (savedSort && Object.keys(savedSort).length) {
                this.sortingForSend = savedSort
                this.saveQuery()
            }
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.blockNumber)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },

        saveQuery() {
            this.router.replace({ query: setQuery(this.sortingForSend, this.filtersForSend) }).catch(() => {})
        },

        getQueryFilter() {
            const { filter = {} } = getQuery()

            if (Object.keys(filter).length) {
                this.filtersForSend = filter
                setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.blockNumber)
            }
        },

        getQuerySorting() {
            const { sorting = {} } = getQuery()

            if (Object.keys(sorting).length) {
                this.sortingForSend = sorting
                setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.blockNumber)
            }
        },

        getQueryLocalStorage() {
            this.getLocalStorageHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getQueryFilter()
                this.getQuerySorting()
                this.getLocalStorageFilter()
                this.getLocalStorageSorting()
                this.getLocalStorageOnPage()
            }

            this.goToLoadBlockedNumbers()
        },

        setVisibleHeaders() {
            this.visibleHeadersTable = getActiveHeadersTable(BLOCK_NUMBER_HEADERS, this.activeColumnsTable)
        },

        changeHeaders(payload) {
            this.activeColumnsTable = payload
            this.setVisibleHeaders()
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },
    },
})
