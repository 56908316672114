const VIEWS_OPTIONS = {
    setupNumberPool: {
        view: 'setupNumberPool',
        title: 'Number Pools',
    },
    addTrackingNumbers: {
        view: 'addTrackingNumbers',
        title: 'Add Tracking Numbers',
    },
    buyTrackingNumbers: {
        view: 'buyTrackingNumbers',
        title: 'Buy Tracking Numbers',
    },
}

export {
    VIEWS_OPTIONS,
}
