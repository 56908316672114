import { defineStore } from 'pinia'

export const useDefaultStore = defineStore('default', {
    state: () => ({
        message: null,
        type: null,
        duration: null,
    }),

    actions: {
        setSuccessMessage(payload) {
            if (!payload.message) {
                return
            }

            this.message = payload.message
            this.type = 'success-bg'
            this.duration = 6000
        },

        setErrorMessage(payload) {
            if (!payload.message) {
                return
            }

            this.message = payload.message
            this.type = 'error-bg'
            this.duration = 15000
        },

        setInfoMessage(payload) {
            if (!payload.message) {
                return
            }

            this.message = payload.message
            this.type = 'info-bg'
            this.duration = 6000
        },

        clearMessage() {
            this.message = null
            this.type = null
            this.duration = null
        },
    },
})
