function getNestedValue(item, key) {
    return key.split('.').reduce((value, currentKey) => value && value[currentKey], item)
}

function localFilterList(items, rule, values, key) {
    switch (rule) {
    case 'is':
        return items.filter((item) => getNestedValue(item, key) && values.includes(getNestedValue(item, key)))

    case 'is_not':
        return items.filter((item) => getNestedValue(item, key) && !values.includes(getNestedValue(item, key)))

    default:
        return items
    }
}

function localFilterString(items, rule, value, key) {
    switch (rule) {
    case 'contains':
        return items.filter((item) => getNestedValue(item, key) && getNestedValue(item, key).toLowerCase().includes(value.toLowerCase()))

    case 'is':
        return items.filter((item) => getNestedValue(item, key) && getNestedValue(item, key).toLowerCase() === value.toLowerCase())

    case 'is_not':
        return items.filter((item) => getNestedValue(item, key) && getNestedValue(item, key).toLowerCase() !== value.toLowerCase())

    case 'does_not_contains':
        return items.filter((item) => getNestedValue(item, key) && !getNestedValue(item, key).toLowerCase().includes(value.toLowerCase()))

    case 'starts_with':
        return items.filter((item) => getNestedValue(item, key) && getNestedValue(item, key).toLowerCase().startsWith(value.toLowerCase()))

    case 'ends_with':
        return items.filter((item) => getNestedValue(item, key) && getNestedValue(item, key).toLowerCase().endsWith(value.toLowerCase()))

    default:
        return items
    }
}

function localFilterNumber(items, rule, value, key) {
    switch (rule) {
    case 'equal':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) === Number(value))

    case 'not_equal':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) !== Number(value))

    case 'greater_than':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) > Number(value))

    case 'less_than':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) < Number(value))

    case 'greater_than_or_equal':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) >= Number(value))

    case 'less_than_or_equal':
        return items.filter((item) => getNestedValue(item, key) && Number(getNestedValue(item, key)) <= Number(value))

    case 'is_empty':
        return items.filter((item) => !getNestedValue(item, key))

    case 'is_not_empty':
        return items.filter((item) => getNestedValue(item, key))

    default:
        return items
    }
}

function localFilterBoolean(items, rule, values, key) {
    switch (rule) {
    case 'is':
        return items.filter((item) => values.includes(Number(getNestedValue(item, key))))

    case 'is_not':
        return items.filter((item) => !values.includes(Number(getNestedValue(item, key))))

    default:
        return items
    }
}

export {
    localFilterList,
    localFilterString,
    localFilterNumber,
    localFilterBoolean,
}
