import { getAccessToken } from '@/helpers/auth-helper'

import { ROUTES } from '@/constants/routes'
import { useAuthorizationStore } from '@/store/authorizationStore'

export default function notFound({ router }) {
    if (!getAccessToken()) {
        return router.push({ name: ROUTES.authLogin })
    }

    const authorizationStore = useAuthorizationStore()

    return router.push({ name: ROUTES.reporting, params: { workspace: authorizationStore.getWorkspaceSlug } })
}
