<template>
    <div class="flex items-center gap-x-2">
        <DollarHeaderIcon class="text-gray-370 dark:text-white"/>

        <div class="text-xs font-medium text-green-600 dark:text-green">{{ accountBalance }}</div>
    </div>
</template>

<script setup>
import {
    computed, onUnmounted, ref, watch,
} from 'vue'

import DollarHeaderIcon from '@/components/icons/DollarHeaderIcon.vue'

import { getHeaderBalance } from '@/api/headers-routes'
import { currency } from '@/helpers/app-helper'

import { useDefaultStore } from '@/store/defaultStore'

const props = defineProps({
    workspace: {
        type: Object,
        default: () => ({}),
    },
})

const defaultStore = useDefaultStore()

const balance = ref(0)
const balanceInterval = ref(null)

const accountBalance = computed(() => currency(balance.value, 'USD', 3))

function setBalanceInterval() {
    clearInterval(balanceInterval.value)

    loadHeaderBalance()

    balanceInterval.value = setInterval(() => {
        loadHeaderBalance()
    }, 60000)
}

async function loadHeaderBalance() {
    const { success, payload, message } = await getHeaderBalance()

    if (success) {
        balance.value = payload
    } else {
        defaultStore.setErrorMessage({ message })
    }
}

watch(() => props.workspace, (newWorkspace) => {
    if (newWorkspace) {
        setBalanceInterval()
    }
}, { immediate: true })

onUnmounted(() => {
    clearInterval(balanceInterval.value)
})
</script>
