export default function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index]

    if (!subsequentMiddleware) {
        return context.next
    }

    return (...parameters) => {
        const nextMiddleware = nextFactory(context, middleware, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })

        context.next(...parameters)
    }
}
