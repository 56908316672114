<template>
    <CustomMenu
        v-model="menu"
        hideTransitionSlider
    >
        <template #activator>
            <div
                v-if="isNotifications"
                class="relative cursor-pointer"
                @click="menuHandler"
            >
                <div class="absolute top-1px left-0.5 w-2 h-2 rounded-full">
                  <div class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75"/>
                  <div class="w-2 h-2 rounded-full bg-red"/>
                </div>

                <NotificationIcon class="text-gray-370 dark:text-white"/>
            </div>

            <NotificationOffIcon
                v-else
                class="text-gray-370 dark:text-white cursor-pointer"
                @click="menuHandler"
            />
        </template>

        <template #dropdown>
            <div
                class="max-mob:w-full w-102.5 py-5 fixed shadow-notifications bg-white dark:bg-gray-790 rounded-2.5"
                ref="dropdownRef"
                :style="position"
                @click.stop
            >
                <div class="flex items-center justify-between mx-5 mb-1.25 pb-3.75 border-b-1 border-gray-100 dark:border-gray-700">
                    <div class="text-lg font-medium text-gray-370 dark:text-white">NOTIFICATIONS</div>

                    <div
                        v-if="isNotifications"
                        class="text-xs text-purple"
                        :class="loadingRead ? 'cursor-wait' : 'cursor-pointer'"
                        @click="onRead(null, null)"
                    >
                        Mark all as read
                    </div>
                </div>

                <NotificationsList
                    v-if="isNotifications"
                    :loading="loadingRead"
                    :notifications="notifications"
                    @onRead="onRead"
                />

                <div
                    v-else
                    class="h-17 flex justify-center items-center text-xs text-gray-280"
                >
                    You don’t have new notifications
                </div>
            </div>
        </template>
    </CustomMenu>
</template>

<script setup>
import {
    computed, inject, onUnmounted, ref, watch,
} from 'vue'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import NotificationsList from '@/components/lists/NotificationsList.vue'
import NotificationIcon from '@/components/icons/NotificationIcon.vue'
import NotificationOffIcon from '@/components/icons/NotificationOffIcon.vue'

import { getNotifications, updateNotification } from '@/api/notifications-routes'
import { viewPorts } from '@/constants/viewPorts'
import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

const props = defineProps({
    workspace: {
        type: Object,
        default: () => ({}),
    },
})

const currentViewSize = inject('currentViewSize')

const defaultStore = useDefaultStore()
const authorizationStore = useAuthorizationStore()

const menu = ref(false)

const loading = ref(false)
const loadingRead = ref(false)

const notifications = ref([])
const notificationInterval = ref(null)

const dropdownRef = ref(null)

const isMobView = computed(() => viewPorts.mob >= currentViewSize.value)

const position = computed(() => {
    if (!dropdownRef.value) return
    const paddingApp = 72
    const elem = dropdownRef.value.getBoundingClientRect()
    const left = window.innerWidth - elem.width - paddingApp

    return {
        left: isMobView.value ? '0' : `${left}px`,
        top: isMobView.value ? 'h-app-bar' : 'auto',
    }
})

const isNotifications = computed(() => notifications.value.length)

const account = computed(() => authorizationStore.account || {})

function menuHandler() {
    if (loading.value) {
        return
    }

    menu.value = !menu.value
}

async function onRead(id, index) {
    loadingRead.value = true

    const { success, message } = await updateNotification(id ? { id } : null)

    if (success) {
        notificationsAfterRead(id, index)
        defaultStore.setSuccessMessage({ message })
    } else {
        defaultStore.setErrorMessage({ message })
    }

    loadingRead.value = false
}

function notificationsAfterRead(id, index) {
    if (id) {
        notifications.value[index].read_at = new Date()
    } else {
        notifications.value = []
    }
}

function setIntervalNotification() {
    clearInterval(notificationInterval.value)

    loadNotifications()

    notificationInterval.value = setInterval(() => {
        loadNotifications()
    }, 60000)
}

async function loadNotifications() {
    loading.value = true

    const { success, payload, message } = await getNotifications()

    if (success) {
        notifications.value = payload
    } else {
        defaultStore.setErrorMessage({ message })
    }

    loading.value = false
}

watch(() => props.workspace, (newWorkspace) => {
    if (account.value.trial_ended) {
        return
    }

    if (newWorkspace && Object.keys(newWorkspace).length) {
        setIntervalNotification()
    }
}, { immediate: true })

onUnmounted(() => {
    clearInterval(notificationInterval.value)
})
</script>
