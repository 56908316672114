import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getNotifications = (params) => axios.get(`${getWorkspaceId()}/notifications`, { params })
const updateNotification = (formatData) => axios.patch(`${getWorkspaceId()}/notifications`, formatData)

export {
    getNotifications,
    updateNotification,
}
