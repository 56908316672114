import { defineStore } from 'pinia'

import {
    updateNumbersStatus,
    getNumbersForManager,
    getNumbersForVendor,
    deleteNumber,
    getSearchNumbers,
    getSearchNumbersForVendor,
    getNumberFiltersAndSorting,
    getNumbersCounters,
    resetNumberCaps,
} from '@/api/number-routes'

import {
    addFilterInColumn,
    checkEqualsFilter,
    createFiltersForSend,
    createSortForSend,
    getQuery,
    setFilter,
    setQuery,
    setSorting,
    updateHeaderAfterSort,
    updateSortHeaderColumn,
    updateSortingAfterReset,
    updateSortingControl,
} from '@/helpers/filters-sorting-header-table-helper'
import {
    getFilterOnPageInLocalStorageByWorkspaceId,
    getSortingInLocalStorageByWorkspaceId, setFilterOnPageInLocalStorageByWorkspaceId,
    setSortingInLocalStorageByWorkspaceId,
} from '@/helpers/save-sorting-local-storage-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'
import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { removeFirstPlusForSearch } from '@/helpers/app-helper'

import { useDefaultStore } from '@/store/defaultStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useDetailTrackingNumbersStore } from '@/store/call-tracking/detailTrackingNumberStore'

import { SORT_RULES } from '@/constants/sortRules'
import { FILTER_RULES } from '@/constants/filterRules'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { MANAGE_NUMBER_FOR_MANAGER_HEADERS, MANAGE_NUMBER_FOR_VENDOR_HEADERS } from '@/constants/headersTable/manageNumbersHeadersTable'
import { STATUS_IDS } from '@/constants/statusIds'
import { viewPorts } from '@/constants/viewPorts'

export const useTrackingNumbersStore = defineStore('trackingNumbers', {
    state: () => ({
        loading: false,
        actionLoading: false,
        statusLoading: false,
        notNeedRequestChangePage: true,
        liveLoading: false,
        capLoading: null,
        liveInterval: null,
        dataCounters: {},
        countersLoading: {},
        dontShowCounterArrow: true,
        dataWarningStatus: {},
        items: [],
        slugsForCounters: [],
        selected: [],
        page: 1,
        total: 1,
        onPage: 25,
        search: null,
        filters: [],
        sorting: [],
        selectedFilters: [],
        selectedSorting: [],
        filtersForSend: {},
        sortingForSend: {},
        activeColumnsTable: [],
        visibleHeadersTable: [],
    }),

    getters: {
        controlParams(state) {
            return {
                filters: state.filters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: state.sorting,
            }
        },

        isManager() {
            const authorizationStore = useAuthorizationStore()
            return authorizationStore.isManager
        },

        isVendor() {
            const authorizationStore = useAuthorizationStore()
            return authorizationStore.isVendor
        },

        headerByRole() {
            return this.isVendor ? MANAGE_NUMBER_FOR_VENDOR_HEADERS : MANAGE_NUMBER_FOR_MANAGER_HEADERS
        },
    },

    actions: {
        clearItems() {
            this.items = []
        },

        changeTrackingNumberAfterUpdate(item) {
            const index = this.items.findIndex((i) => i.slug === item.slug)

            if (index + 1) {
                this.items.splice(index, 1, item)
            }
        },

        changeTrackingNumberAfterAttach(payload) {
            this.clearSelected()

            if (payload.length) {
                payload.forEach((p) => {
                    const { slug, master_pool = [] } = p
                    const findItem = this.items.find((item) => item.slug === slug)
                    if (findItem) findItem.master_pool = master_pool
                })
            }
        },

        async goToLoadTrackingNumberFiltersAndSorting() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getNumberFiltersAndSorting()

            if (success) {
                this.goToSetTrackingNumberFilters(payload)
                this.goToSetTrackingNumberSorting(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        goToSetTrackingNumberFilters({ filters }) {
            this.filters = filters.filter((filter) => filter.type !== 'date')
            this.updateFilterAfterQueryLocalStorage(this.filtersForSend)
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },

        goToSetTrackingNumberSorting({ sorting }) {
            this.sorting = sorting.filter((filter) => filter.key !== 'byAllocated')
            this.updateSortingAfterQueryLocalStorage(this.sortingForSend)
        },

        async goToLoadTrackingNumbers() {
            const defaultStore = useDefaultStore()

            this.loading = true

            this.notNeedRequestChangePage = false

            const body = {
                page: this.page,
                search: removeFirstPlusForSearch(this.search),
                onPage: this.onPage,
                ...this.filtersForSend,
                ...this.sortingForSend,
            }

            const { success, payload, message } = this.isManager
                ? await getNumbersForManager(body)
                : await getNumbersForVendor(body)

            if (success) {
                const { data = [], current_page, last_page } = payload
                this.items = data
                this.page = current_page
                this.total = last_page

                this.checkValidPage()
                this.setLoadCountersInterval(this.items.map((number) => number.slug))
            } else {
                this.loading = false
                defaultStore.setErrorMessage({ message })
            }
        },

        setLoadCountersInterval(slugsForCounters = []) {
            clearInterval(this.liveInterval)

            if (!this.isManager) {
                return
            }

            if (!slugsForCounters.length) {
                return
            }

            this.slugsForCounters = slugsForCounters

            this.goToLoadNumbersCounters()

            this.liveInterval = setInterval(() => {
                this.goToLoadNumbersCounters()
            }, 30000)
        },

        removeCountersInterval() {
            clearInterval(this.liveInterval)
        },

        async goToLoadNumbersCounters() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getNumbersCounters({
                numbers: this.slugsForCounters,
            })

            if (success) {
                this.parseDataCounters(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        parseDataCounters(payload = []) {
            if (!payload.length) {
                return
            }

            payload.forEach((item) => {
                const {
                    slug, capsCounter = {}, liveCounter, warningStatus,
                } = item

                this.dataCounters[slug] = {
                    ...capsCounter,
                    live: liveCounter,
                }

                this.dataWarningStatus[slug] = warningStatus
            })

            setTimeout(() => {
                this.dontShowCounterArrow = false
            }, 1000)
        },

        async goToUpdateCountersTrackingNumber({ formData, counterName }) {
            const detailTrackingNumbersStore = useDetailTrackingNumbersStore()

            const counterLoadingKey = `${formData.slug}-${counterName}`

            this.changeCountersLoading({ counterLoadingKey })

            detailTrackingNumbersStore.setTrackingNumberSlug(formData.slug)
            detailTrackingNumbersStore.parsePayload(formData)

            const { success } = await detailTrackingNumbersStore.goToUpdateTrackingNumber()

            if (success) {
                this.changeTrackingNumberAfterUpdate(formData)
            }

            this.changeCountersLoading({ counterLoadingKey, value: false })
        },

        changeCountersLoading({ counterLoadingKey, value = true }) {
            this.countersLoading[counterLoadingKey] = value
        },

        async goToUpdateLiveTrackingNumber(item, value) {
            const detailTrackingNumbersStore = useDetailTrackingNumbersStore()

            this.liveLoading = item.slug

            detailTrackingNumbersStore.setTrackingNumberSlug(item.slug)
            detailTrackingNumbersStore.parsePayload(item)

            const { success, payload } = await detailTrackingNumbersStore.goToUpdateTrackingNumber({
                max_concurrency: value,
            })

            if (success) {
                this.changeTrackingNumberAfterUpdate(payload)
            }

            this.liveLoading = false
        },

        async goToUpdateCapTrackingNumber(formData, columnIndex) {
            const detailTrackingNumbersStore = useDetailTrackingNumbersStore()

            this.capLoading = `${formData.slug}-${columnIndex}`

            detailTrackingNumbersStore.setTrackingNumberSlug(formData.slug)
            detailTrackingNumbersStore.parsePayload(formData)

            const { success, payload } = await detailTrackingNumbersStore.goToUpdateTrackingNumber(formData)

            if (success) {
                this.changeTrackingNumberAfterUpdate(payload)
            }

            this.capLoading = null
        },

        async goToUpdateTrackingNumbersStatus({ number = null, numbers = [], status }) {
            if (this.actionLoading) {
                return
            }

            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { active, noActive } = STATUS_IDS

            const formData = {}

            if (number) {
                formData.slugs = [number.slug]
                formData.status_id = number.status_id === active ? noActive : active
            } else {
                formData.slugs = numbers.map((c) => c.slug)
                formData.status_id = status
            }

            const { success, payload, message } = await updateNumbersStatus(formData)

            if (success) {
                this.updateAfterChangeStatuses(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return { success, payload }
        },

        updateAfterChangeStatuses(numbers) {
            numbers.forEach((number) => {
                const index = this.items.findIndex((i) => i.slug === number.slug)

                if (index + 1) {
                    this.items[index].status_id = number.status_id
                }
            })
        },

        async goToDeleteTrackingNumber(slugs) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { success, message } = await deleteNumber({ slugs })

            if (success) {
                this.clearSelected()

                this.goToLoadTrackingNumbers()

                this.checkValidPage()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToResetTrackingNumberCaps(item) {
            const defaultStore = useDefaultStore()

            this.actionLoading = true

            const { slug } = item
            const { success, message } = await resetNumberCaps(slug)

            if (success) {
                this.goToLoadNumbersCounters()

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.actionLoading = false

            return success
        },

        async goToSearchTrackingNumber(body) {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = this.isManager
                ? await getSearchNumbers(body)
                : await getSearchNumbersForVendor(body)

            if (!success) {
                defaultStore.setErrorMessage({ message })
            }

            return { success, payload }
        },

        onSearch(searchValue) {
            this.clearSelected()
            this.search = searchValue
            this.changePage(1)
        },

        sendFiltersSorting() {
            this.preparationFiltersForSend()
            this.preparationSortingForSend()
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        preparationFiltersForSend() {
            const newFilter = createFiltersForSend(this.selectedFilters)

            if (!checkEqualsFilter(newFilter, this.filtersForSend)) {
                this.filtersForSend = newFilter
            }
        },

        preparationSortingForSend() {
            this.sortingForSend = createSortForSend(this.selectedSorting, this.visibleHeadersTable)
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable, this.sortingForSend)
        },

        changePage(newPage) {
            this.dontShowCounterArrow = true

            this.page = newPage

            if (!this.notNeedRequestChangePage) {
                this.goToLoadTrackingNumbers()
            }
        },

        changeOnPage(onPage) {
            this.onPage = onPage

            setFilterOnPageInLocalStorageByWorkspaceId(onPage, SETTINGS_HEADER_TABLE.manageNumber)

            this.goToLoadTrackingNumbers()
        },

        checkValidPage() {
            if (this.page > this.total) {
                this.changePage(this.total)
            } else {
                this.loading = false
            }
        },

        changeSelectedItems(newSelectedItems) {
            this.selected = newSelectedItems
        },

        clearSelected() {
            this.selected = []
        },

        updateSelectedFilters(payload) {
            this.clearSelected()
            this.selectedFilters = payload
            this.sendFiltersSorting()
        },

        updateSortingForSend(item, value) {
            this.sorting = updateSortingControl({ sortSettings: { sortBy: item.key } }, this.sorting, value)
        },

        createSelectedSorting(item, key) {
            const role = key ? SORT_RULES.find((r) => r.key === key) : SORT_RULES[0]
            this.selectedSorting = [...this.selectedSorting, { ...item, value: role }]
        },

        changeSelectedSortingAfterDelete(item) {
            this.selectedSorting = this.selectedSorting.filter((el) => el.key !== item.key)

            this.updateSortingForSend(item, false)
            this.sendFiltersSorting()
        },

        changeSelectedSortingAfterAdd(item) {
            this.createSelectedSorting(item)
            this.updateSortingForSend(item, true)
            this.sendFiltersSorting()
        },

        removeAllSorting() {
            this.selectedSorting = []
            this.sorting = updateSortingAfterReset(this.sorting)
        },

        resetSortFilter() {
            this.clearSelected()
            this.selectedFilters = []
            this.removeAllSorting()
            this.filtersForSend = null
            this.sortingForSend = {}
            this.visibleHeadersTable = updateHeaderAfterSort(this.visibleHeadersTable)
            this.saveFiltersSortingInLocalStorage()
            this.saveQuery()
            this.changePage(1)
        },

        addSortingFromTable(sort) {
            const sortFilterTableStore = useSortFilterTableStore()

            this.selectedSorting = setSorting(sort, [...this.selectedSorting])
            this.visibleHeadersTable = updateSortHeaderColumn(sort, this.visibleHeadersTable)
            this.sorting = updateSortingControl(sort, this.sorting, true)
            this.sendFiltersSorting()
            sortFilterTableStore.openCloseTableSortMenu(true)
            sortFilterTableStore.openCloseSorting(true)
        },

        addFilterFromTable(filter) {
            const index = this.selectedFilters.findIndex((item) => item.key === filter.filterSettings.key)

            const sortFilterTableStore = useSortFilterTableStore()

            const { items, filterOpen } = setFilter(filter, [...this.selectedFilters])

            if (index === -1) {
                this.updateSelectedFilters(items)
            }

            sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.manageNumber, filterOpen)
            sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.manageNumber)
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.filters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.selectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.manageNumber, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.manageNumber)
            }
        },

        updateSortingAfterQueryLocalStorage(savedSort) {
            const sortFilterTableStore = useSortFilterTableStore()

            if (!Object.keys(savedSort).length) {
                return
            }

            Object.keys(savedSort).forEach((key) => {
                const findItem = this.sorting.find((el) => el.key === key)

                if (findItem) {
                    this.createSelectedSorting(findItem, savedSort[key])
                    this.updateSortingForSend(findItem, true)
                    sortFilterTableStore.openCloseSorting(true)
                }
            })

            this.addSortingToHeader()
        },

        addSortingToHeader() {
            this.selectedSorting.forEach((sort) => {
                const obj = {
                    sortSettings: {
                        sortBy: sort.key,
                    },
                    ...sort,
                }

                this.visibleHeadersTable = updateSortHeaderColumn(obj, this.visibleHeadersTable)
            })
        },

        saveFiltersSortingInLocalStorage() {
            setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.manageNumber)
            setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.manageNumber)
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTable = getActiveColumnsHeader(this.headerByRole, SETTINGS_HEADER_TABLE.manageNumber)
            this.setVisibleHeaders()
        },

        getLocalStorageFilter() {
            const savedFilter = getFilterLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.manageNumber)

            if (savedFilter && Object.keys(savedFilter).length) {
                this.filtersForSend = savedFilter
                this.saveQuery()
            }
        },

        getLocalStorageSorting() {
            const savedSort = getSortingInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.manageNumber)

            if (savedSort && Object.keys(savedSort).length) {
                this.sortingForSend = savedSort
                this.saveQuery()
            }
        },

        getLocalStorageOnPage() {
            const savedOnPage = getFilterOnPageInLocalStorageByWorkspaceId(SETTINGS_HEADER_TABLE.manageNumber)

            this.onPage = savedOnPage ? Number(savedOnPage) : 25
        },

        saveQuery() {
            this.router.replace({ query: setQuery(this.sortingForSend, this.filtersForSend) }).catch(() => {})
        },

        getQueryFilter() {
            const { filter = {} } = getQuery()

            if (Object.keys(filter).length) {
                this.filtersForSend = filter
                setFilterInLocalStorageByWorkspaceId(this.filtersForSend, SETTINGS_HEADER_TABLE.manageNumber)
            }
        },

        getQuerySorting() {
            const { sorting = {} } = getQuery()

            if (Object.keys(sorting).length) {
                this.sortingForSend = sorting
                setSortingInLocalStorageByWorkspaceId(this.sortingForSend, SETTINGS_HEADER_TABLE.manageNumber)
            }
        },

        getQueryLocalStorage() {
            this.getLocalStorageHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getQueryFilter()
                this.getQuerySorting()
                this.getLocalStorageFilter()
                this.getLocalStorageSorting()
                this.getLocalStorageOnPage()
            }

            this.goToLoadTrackingNumbers()
        },

        setVisibleHeaders() {
            this.visibleHeadersTable = getActiveHeadersTable(this.headerByRole, this.activeColumnsTable)
        },

        changeHeaders(payload) {
            this.activeColumnsTable = payload
            this.setVisibleHeaders()
            this.visibleHeadersTable = addFilterInColumn(this.visibleHeadersTable, this.filters)
        },
    },
})
