export const TIME_GROUPS = {
    byHour: {
        id: 'byHour',
        name: 'H',
    },
    byDay: {
        id: 'byDay',
        name: 'D',
    },
    byMonth: {
        id: 'byMonth',
        name: 'M',
    },
}
