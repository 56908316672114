import { ROUTES } from '@/constants/routes'

export default function accountInReview({ next, router, store }) {
    const { account, getWorkspaceSlug } = store

    if (account && account.in_review) {
        return router.push({ name: ROUTES.verification, params: { workspace: getWorkspaceSlug } })
    }

    next()
}
