import { createRouter, createWebHistory } from 'vue-router'
import { getAccessToken, setAccessToken } from '@/helpers/auth-helper'

import nextFactory from '@/router/middleware/nextFactory'

import { ROUTES } from '@/constants/routes'
import { BASE_ROUTES } from '@/router/config/baseRoutes'

import { useAppStore } from '@/store/appStore'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useWhiteLabelStore } from '@/store/call-tracking/whiteLabelStore'

function setTitle({ meta = {} }) {
    const whiteLabelStore = useWhiteLabelStore()

    const { title = 'Dialics' } = meta

    document.title = whiteLabelStore.whiteLabelIsEnabled ? whiteLabelStore.whiteLabelData.company_name || title : title
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: BASE_ROUTES,
})

router.beforeEach(async (to, from, next) => {
    setTitle(to)

    const authorizationStore = useAuthorizationStore()
    const whiteLabelStore = useWhiteLabelStore()
    const appStore = useAppStore()
    appStore.setRouteLoading(true)

    const { query } = to

    if (query.adminToken) {
        setAccessToken(query.adminToken)
    }
    await whiteLabelStore.getCheckWhiteLabel()

    if (!!getAccessToken() && !authorizationStore.role) {
        authorizationStore.$reset()
        await authorizationStore.getCheckUserAccess()

        const workspaceFromURL = to.params?.workspace ? to.params.workspace : null
        const accountFromURL = to.params?.account ? to.params.account : null

        await authorizationStore.getCheckWorkspace(workspaceFromURL)

        if (authorizationStore.workspace && authorizationStore.workspace.owner_blocked) {
            return next({ name: ROUTES.accountDisabled })
        }

        if (
            !!workspaceFromURL && !!authorizationStore.workspace && authorizationStore.workspace.hasOwnProperty('slug')
            && to.params.workspace !== authorizationStore.workspace.slug
        ) {
            return authorizationStore.isOwner
                ? next({ name: to.name, params: { workspace: authorizationStore.workspace.slug, account: authorizationStore.account.slug } })
                : next({ name: to.name, params: { workspace: authorizationStore.workspace.slug } })
        }

        if (
            !!accountFromURL && !!authorizationStore.account && authorizationStore.account.hasOwnProperty('slug')
            && to.params.account !== authorizationStore.account.slug
        ) {
            return next({
                name: to.name,
                params: { account: authorizationStore.account.slug, workspace: workspaceFromURL },
                query: to.query,
            })
        }
    }

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = {
            to, from, next, store: authorizationStore, router,
        }

        const nextMiddleware = nextFactory(context, middleware, 1)

        return middleware[0]({ ...context, next: nextMiddleware })
            .then(() => next()) // Call `next()` after the first middleware finishes
            .catch((error) => {
                next(error) // Pass the error to the next middleware or the error handler
            })
    }

    return next()
})

router.onError((error, to) => {
    window.open(to.path, '_self')
})

router.beforeResolve(() => {
    const appStore = useAppStore()
    appStore.setRouteLoading(false)
})

export default router
