<template>
    <div :class="mini ? 'mx-2.75 mb-5' : 'mx-4'">
        <CustomDivider
            class="my-5"
            color="border-gray-100 dark:border-gray-760"
        />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import CustomDivider from '@/components/divider/CustomDivider.vue'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
})

const showContent = ref(false)

watch(() => props.mini, (mini) => {
    if (!mini) {
        setTimeout(() => {
            showContent.value = mini
        }, 100)
    } else {
        showContent.value = mini
    }
})
</script>
