export const FILTERS_REPORTING = {
    campaign: {
        filter: 'byCampaign',
        column: 'campaign',
        key: 'campaigns',
    },
    vendor: {
        filter: 'byVendor',
        column: 'vendor',
        key: 'vendors',
    },
    number: {
        filter: 'byNumber',
        column: 'number',
        key: 'numbers',
    },
    replacementNumber: {
        filter: 'byReplacementNumber',
        column: 'replacementNumber',
        key: 'replacementNumber',
    },
    callers: {
        filter: 'callers',
        column: 'callers',
        key: 'callers',
    },
    destination: {
        filter: 'byDestination',
        column: 'destination',
        key: 'destinations',
    },
    buyer: {
        filter: 'byBuyer',
        column: 'buyer',
        key: 'buyers',
    },
    trafficSource: {
        filter: 'byTrafficSource',
        column: 'trafficSource',
        key: 'trafficSources',
    },
    parameters: {
        filter: 'byParameters',
        column: 'parameters',
        key: 'parameters',
    },
    callerProfile: {
        filter: 'byCallerProfile',
        column: 'callerProfile',
        key: 'callerProfile',
    },
    duration: {
        filter: 'byDuration',
        column: 'duration',
        key: 'duration',
    },
    tags: {
        filter: 'byTags',
        column: 'tags',
        key: 'tags',
    },
    dupe: {
        column: 'dupe',
    },
    revenue: {
        column: 'revenue',
    },
    payout: {
        column: 'payout',
    },
    incoming: {
        column: 'incoming',
    },
    connected: {
        column: 'connected',
    },
    converted: {
        column: 'converted',
    },
    qualified: {
        column: 'qualified',
    },
    paid: {
        column: 'paid',
    },
    noConnect: {
        column: 'noConnect',
    },
    byDate: {
        filter: 'byDate',
    },
    // byReplacementNumber: {
    //     filter: 'byReplacementNumber',
    // },
}
