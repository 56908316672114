import { defineStore } from 'pinia'

import { getCountries, getLanguages, getTimezones } from '@/api/app-routes'

import { useDefaultStore } from '@/store/defaultStore'

export const useAppStore = defineStore('app', {
    state: () => ({
        timezones: [],
        countries: [],
        languages: [],
        themeTimerId: null,
        routeIsLoading: false,
    }),

    actions: {
        setRouteLoading(loading) {
            this.routeIsLoading = loading
        },

        changeTimerId(id) {
            this.themeTimerId = id
        },

        getTimezoneNameById(id) {
            const timezone = this.timezones.find((timezone) => timezone.id === id)

            if (!!timezone && timezone.hasOwnProperty('display')) {
                return timezone.display
            }

            return ''
        },

        getTimezoneOffsetById(id) {
            const timezone = this.timezones.find((timezone) => timezone.id === id)

            return timezone ? timezone.php : null
        },

        getCountryNameById(id) {
            const country = this.countries.find((country) => country.id === id)

            if (!!country && country.hasOwnProperty('name')) {
                return country.name
            }

            return ''
        },

        getCountryCodeById(id) {
            const country = this.countries.find((country) => country.id === id)

            if (!!country && country.hasOwnProperty('code')) {
                return country.code
            }

            return ''
        },

        getCountryPrefixById(id) {
            const country = this.countries.find((country) => country.id === id)

            if (!!country && country.hasOwnProperty('num_code')) {
                return country.num_code
            }

            return ''
        },

        getCountryById(id) {
            return this.countries.find((country) => country.id === id)
        },

        getCountriesWithCode() {
            return this.countries.map((country) => ({
                ...country,
                name: `${country.name} (${country.num_code})`,
            }))
        },

        async loadLanguages() {
            if (Array.isArray(this.languages) && this.languages.length) {
                return
            }

            const { success, payload, message } = await getLanguages()

            if (success) {
                this.languages = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async loadTimezones() {
            if (Array.isArray(this.timezones) && this.timezones.length) {
                return
            }

            const { success, payload, message } = await getTimezones()

            if (success) {
                this.timezones = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async loadCountries() {
            if (Array.isArray(this.countries) && this.countries.length) {
                return
            }

            const { success, payload, message } = await getCountries()

            if (success) {
                this.countries = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },
    },
})
