import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getBlockedNumbers = (params) => axios.get(`${getWorkspaceId()}/blocked-numbers/paginated`, { params })
const sendBlockedNumber = (formData) => axios.post(`${getWorkspaceId()}/blocked-numbers`, formData)
const updateBlockedNumber = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/blocked-numbers/${slug}`, formData)
const unblockNumber = (data) => axios.delete(`${getWorkspaceId()}/blocked-numbers`, { data })

const getBlockNumberFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/blocked-numbers/filters-and-sorting`)

export {
    getBlockedNumbers,
    sendBlockedNumber,
    updateBlockedNumber,
    unblockNumber,
    getBlockNumberFiltersAndSorting,
}
