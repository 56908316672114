const APP_ROLES = {
    owner: 'Owner',
    manager: 'Manager',
    reporter: 'Reporter',
    vendor: 'Vendor',
    buyer: 'Buyer',
}

export {
    APP_ROLES,
}
