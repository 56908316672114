import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getPoolsAll = () => axios.get(`${getWorkspaceId()}/number-pools/all`)
const getPools = (params) => axios.get(`${getWorkspaceId()}/number-pools/paginated`, { params })
const getAvailableNumbersPools = (slug) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/available-numbers`)
const createPool = (formData) => axios.post(`${getWorkspaceId()}/number-pools`, formData)
const updatePool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}`, formData)
const deletePool = (data) => axios.delete(`${getWorkspaceId()}/number-pools`, { data })
const autoBuyPool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/buy-numbers`, formData)
const detachPool = ({ slug, data }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/reset-numbers`, { data })
const getDetailPool = (slug) => axios.get(`${getWorkspaceId()}/number-pools/${slug}`)

const getJsCode = ({ slug, numberSlug }) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/numbers/${numberSlug}/integration-codes`)
const downloadDNIIntegrationFile = ({ slug, numberSlug }) => axios.get(`${getWorkspaceId()}/number-pools/${slug}/numbers/${numberSlug}/integration-file`)

const attachNumbersToPool = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/attach-numbers`, formData)
const buyAndAttachNumbersToPool = ({ slug, formData }) => axios.post(`${getWorkspaceId()}/number-pools/${slug}/phone-numbers`, formData)
const detachNumbersToPool = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/detach-numbers`, { data })

const attachPoolToNumber = ({ slug, formData }) => axios.patch(`${getWorkspaceId()}/number-pools/${slug}/attach-to-number`, formData)
const detachPoolFromNumber = ({ data, slug }) => axios.delete(`${getWorkspaceId()}/number-pools/${slug}/detach-from-number`, { data })

const getPoolFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/number-pools/filters-and-sorting`)

export {
    getPoolsAll,
    getAvailableNumbersPools,
    getPools,
    createPool,
    updatePool,
    deletePool,
    autoBuyPool,
    detachPool,
    getDetailPool,
    getJsCode,
    attachNumbersToPool,
    detachNumbersToPool,
    attachPoolToNumber,
    detachPoolFromNumber,
    buyAndAttachNumbersToPool,
    getPoolFiltersAndSorting,
    downloadDNIIntegrationFile,
}
