<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_225_12737)">
            <path d="M11.7363 14.7474L16.7464 21.0275C17.3307 21.76 18.5111 21.3587 18.528 20.4219L18.8318 3.58412C18.8461 2.79272 17.9789 2.29922 17.3058 2.7157L2.92944 11.6112C2.13916 12.1002 2.38503 13.3086 3.30356 13.45L11.7363 14.7474ZM11.7363 14.7474L18.2788 2.88966" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_225_12737">
                <rect width="24" height="24" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>
</template>
