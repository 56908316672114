<template>
    <div
        class="flex items-center justify-center rounded-1.25 bg-blue-200 text-white text-3 text-medium uppercase"
        :class="[ mini ? 'min-w-6 min-h-6 max-w-6 max-h-6' : 'min-w-8.5 min-h-8.5 max-w-8.5' ]"
    >
        {{ defaultName }}
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    mini: {
        type: Boolean,
        default: false,
    },
})

const defaultName = computed(() => {
    const arrayString = props.name.split(' ')
    const [first, second] = arrayString

    return props.name ? `${first[0]}${second ? second[0] : ''}` : ' '
})
</script>
