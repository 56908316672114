import { WHITELABEL_PROPERTIES } from '@/constants/whitelabelProperties'

function changeColorsProperty(formData) {
    WHITELABEL_PROPERTIES.forEach(({ color, property }) => {
        _changeColorProperty({
            color: formData[color],
            property,
        })
    })

    _hidePrimaryShadow()
}
function _changeColorProperty({ color, property }) {
    if (!color || !property) {
        return
    }

    const hexColor = color.replace('#', '')

    const red = parseInt(hexColor.substring(0, 2), 16)
    const green = parseInt(hexColor.substring(2, 4), 16)
    const blue = parseInt(hexColor.substring(4, 6), 16)

    document.querySelector(':root').style.setProperty(property, `${red} ${green} ${blue}`)
}

function _hidePrimaryShadow() {
    document.querySelector(':root').style.setProperty('--color-primary-shadow', '0, 0, 0, 0')
}

function changeBorderRadius(radius) {
    document.querySelector(':root').style.setProperty('--radius-btn', `${radius}px`)
}

function changeAppTitle(title) {
    document.title = title || 'Dialics'
}

function changeAppFavicons(icon) {
    const oldLink = document.querySelector("link[rel*='icon']")
    const newLink = document.createElement('link')
    newLink.rel = 'shortcut icon'
    newLink.href = icon

    document.head.appendChild(newLink)

    if (oldLink) {
        document.head.removeChild(oldLink)
    }
}

export {
    changeColorsProperty, changeBorderRadius, changeAppTitle, changeAppFavicons,
}
