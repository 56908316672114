<template>
    <div
        class="fixed flex flex-col justify-between bg-menu-white300 dark:bg-menu-black370 h-full pt-2.5 pb-5 transition-width custom-scroll-y"
        :class="[
            mini ? 'w-side-bar-main-mini' : 'w-side-bar-main',
            showMobileSideBars ? 'z-100' : '-left-offset-side-bar-main min-mb:left-0'
        ]"
        v-touch:swipe.left="swipeLeft"
    >
        <div>
            <WorkspaceMenu
                class="px-3.25 pb-4 pt-1.5"
                :mini="mini"
                :isOwner="isOwner"
                @onChange="changeMini"
            />

            <div
                v-if="mini && !isMbView"
                class="flex group w-6 h-6 mt-2 m-auto items-center justify-center rounded-1.25 hover:bg-menu-purple70 dark:hover:bg-menu-blue770 cursor-pointer"
                @click="changeMini"
            >
                <DoubleArrowIcon class="text-gray-200 dark:text-gray-400 dark:group-hover:text-gray-200" />
            </div>

            <div class="flex flex-col gap-y-1 text-gray-200">
                <template
                    v-for="route in topMenu"
                    :key="route.id"
                >
                    <CustomRouterLink
                        class="relative h-10 px-2.75 flex items-center gap-x-1.25 cursor-pointer before:absolute before:right-0 before:top-0 before:h-0 before:w-0.75 before:bg-purple-140 transition-colors before:transition-all"
                        :class="menuStyle(route)"
                        :route="route"
                        :simpleRote="!!route.routeName"
                        :mini="mini"
                        @click="navigationActionHandler(route)"
                    >
                        <div
                            class="w-9.5 flex item-center justify-center transition-colors"
                        >
                            <component :is="route.icon()" />
                        </div>

                        <div
                            v-if="!mini"
                            class="text-center text-3.25 whitespace-nowrap transition-colors"
                        >
                            {{ route.name }}
                        </div>
                    </CustomRouterLink>
                </template>
            </div>

            <CustomDivider
                class="mt-2 mb-7 max-mob:mb-5 mx-4 max-mob:mx-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <div
                v-if="!mini"
                class="app-text-xs px-4 mb-4"
            >
                MANAGE SYSTEM
            </div>

            <RoutingSideBar
                :mini="mini"
                :showMobileSideBars="showMobileSideBars"
            />
        </div>

        <div>
            <SideBarOneDialics
                v-if="authorizationStore.isWorkspaceOwner && !whiteLabelStore.whiteLabelIsEnabled"
                :mini="mini"
            />

            <div
                v-if="!whiteLabelStore.whiteLabelIsEnabled"
                class="flex flex-col gap-y-2.5 text-gray-370 dark:text-gray-200"
                :class="[ mini && 'items-center' ]"
            >
                <template
                    v-for="route in BOTTOM_MENU_ACCOUNT"
                    :key="route.id"
                >
                    <CustomRouterLink
                        class="relative group h-10 px-2.75 flex items-center gap-x-1.25 hover:bg-menu-purple70 hover:text-gray-370 dark:hover:text-white dark:hover:bg-menu-blue770 cursor-pointer before:h-0 before:absolute before:right-0 before:top-0 before:w-0.5 before:bg-purple-140 transition-colors before:transition-all"
                        :route="route"
                        :simpleRote="!!route.routeName"
                        :mini="mini"
                        @click="navigationActionHandler(route)"
                    >
                        <div class="w-9.5 flex item-center justify-center text-gray-370 dark:text-white transition-colors">
                            <component :is="route.icon()" />
                        </div>

                        <div
                            v-if="!mini"
                            class="text-center text-3.25 whitespace-nowrap text-gray-370 dark:text-white transition-colors"
                        >
                            {{ route.name }}
                        </div>
                    </CustomRouterLink>
                </template>
            </div>

            <CustomDivider
                class="m-4 max-mob:mx-2"
                color="border-gray-100 dark:border-gray-760"
            />

            <SideBarAccount :mini="mini"/>
        </div>

        <GlobalSearchDialog
            v-model="showSearchDialog"
        />
    </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue'
import { useRoute } from 'vue-router'

import GlobalSearchDialog from '@/components/dialogs/GlobalSearchDialog.vue'
import SideBarAccount from '@/components/navigation/SideBar/SideBarAccount.vue'
import CustomRouterLink from '@/components/navigation/CustomRouterLink.vue'
import WorkspaceMenu from '@/components/menu/WorkspaceMenu.vue'
import DoubleArrowIcon from '@/components/icons/arrow/DoubleArrowIcon.vue'
import RoutingSideBar from '@/components/navigation/RoutingSideBar/RoutingSideBar.vue'
import CustomDivider from '@/components/divider/CustomDivider.vue'
import SideBarOneDialics from '@/components/navigation/SideBar/SideBarOneDialics.vue'

import { getDateDistanceToEndDateGettingStart } from '@/helpers/date-helper'

import { useAuthorizationStore } from '@/store/authorizationStore'
import { useWhiteLabelStore } from '@/store/call-tracking/whiteLabelStore'

import {
    GETTING_START, TOP_MENU_ACCOUNT_FOR_OWNER, TOP_MENU_ACCOUNT_FOR_MANAGER, BOTTOM_MENU_ACCOUNT,
} from '@/constants/accountMenu'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'
import { viewPorts } from '@/constants/viewPorts'

const props = defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
    showMobileSideBars: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:mini', 'update:showMobileSideBars'])

const currentViewSize = inject('currentViewSize')

const authorizationStore = useAuthorizationStore()
const whiteLabelStore = useWhiteLabelStore()

const route = useRoute()

const showSearchDialog = ref(false)

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const isManager = computed(() => authorizationStore.isManager)
const isOwner = computed(() => authorizationStore.isOwner)
const isShowGettingStart = computed(() => {
    const { account } = authorizationStore

    if (account) {
        const { created_at } = account

        return getDateDistanceToEndDateGettingStart(created_at)
    }

    return false
})

const menuOwner = computed(() => {
    if (isShowGettingStart.value && !whiteLabelStore.whiteLabelIsEnabled) {
        return [...TOP_MENU_ACCOUNT_FOR_OWNER, GETTING_START]
    }

    return TOP_MENU_ACCOUNT_FOR_OWNER
})

const topMenu = computed(() => {
    if (authorizationStore.isWorkspaceOwner) {
        return menuOwner.value
    }

    if (isManager.value) {
        return TOP_MENU_ACCOUNT_FOR_MANAGER
    }

    return []
})

function navigationActionHandler(route) {
    if (!route.hasOwnProperty('action')) {
        return
    }

    switch (route.action) {
    case NAVIGATION_ACTIONS.search:
        openSearchDialog()
        return
    case NAVIGATION_ACTIONS.help:
        goToHelp()
    }
}

function checkCurrentLink(parent, subMenu) {
    const { routeName, isFirstLoad } = parent

    if (subMenu) {
        const isActive = subMenu.some((el) => el.routeName === route.meta.reference)

        if (isActive && isFirstLoad) {
            parent.isOpenSub = isActive
            parent.isFirstLoad = false
        }

        return isActive
    }
    return route.meta.reference === routeName
}

function menuStyle(route) {
    const { subMenu } = route

    const active = checkCurrentLink(route, subMenu)

    return [
        active
            ? 'before:h-10 bg-menu-purple70 dark:bg-menu-blue770 border-purple dark:border-transparent text-purple dark:text-purple-140'
            : 'hover:bg-menu-purple70 hover:text-gray-370 dark:hover:text-white dark:hover:bg-menu-blue770',
    ].join(' ')
}

function swipeLeft() {
    emits('update:showMobileSideBars', false)
}

function changeMini() {
    emits('update:mini', !props.mini)
}

function goToHelp() {
    window.open(`${import.meta.env.VITE_SITE_LINK}/help-center/`, '_blank')
}

function openSearchDialog() {
    if (authorizationStore.isAccountReview) {
        return
    }

    showSearchDialog.value = !showSearchDialog.value
}
</script>
