import { getAccessToken } from '@/helpers/auth-helper'

import { ROUTES } from '@/constants/routes'

export default function auth({ next, router }) {
    if (!getAccessToken()) {
        return router.push({ name: ROUTES.authLogin })
    }

    next()
}
