import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getHeaderCalls = (params) => axios.get(`${getWorkspaceId()}/calls/header`, { params })
const getHeaderBalance = (params) => axios.get(`${getWorkspaceId()}/settings/balances`, { params })

export {
    getHeaderCalls,
    getHeaderBalance,
}
