export const WHITELABEL_PROPERTIES = [
    { color: 'primary', property: '--color-primary' },
    { color: 'primary', property: '--color-primary-light' },
    { color: 'primary', property: '--color-primary-gradient-start' },
    { color: 'primary', property: '--color-primary-gradient-end' },
    { color: 'primary', property: '--color-tippy-purple-700' },

    { color: 'hover', property: '--color-primary-gradient-hover-start' },
    { color: 'hover', property: '--color-primary-gradient-hover-end' },
    { color: 'hover', property: '--color-primary-gradient-active-start' },
    { color: 'hover', property: '--color-primary-gradient-active-end' },

    { color: 'dark_highlight_color', property: '--color-highlight-dark' },
    { color: 'dark_highlight_color', property: '--color-pop-up-bg-hover-dark' },
    { color: 'light_highlight_color', property: '--color-highlight-light' },
    { color: 'dark_highlight_color', property: '--color-table-select-bg-dark' },
    { color: 'light_highlight_color', property: '--color-table-select-bg-light' },

    { color: 'dark_menu_bg_color', property: '--color-menu-bg-dark' },
    { color: 'dark_menu_bg_color', property: '--color-pop-up-bg-dark' },
    { color: 'light_menu_bg_color', property: '--color-menu-bg-light' },
    { color: 'light_menu_bg_color', property: '--color-pop-up-bg-light' },

    { color: 'dark_buttons_bg_color', property: '--color-sliders-btn-bg-dark' },
    { color: 'light_buttons_bg_color', property: '--color-sliders-btn-bg-light' },
    { color: 'dark_buttons_active_color', property: '--color-sliders-btn-bg-hover-dark' },
    { color: 'light_buttons_active_color', property: '--color-sliders-btn-bg-hover-light' },
]
