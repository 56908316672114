export const NUMBER_IN_POOLS_HEADERS_TABLE = [
    { name: 'Number', value: 'number', disabled: true },
    { name: 'Name', value: 'name' },
    { name: 'Type', value: 'type' },
    {
        name: 'Status', value: 'status', align: 'center', width: 100,
    },
    {
        name: 'Actions', value: 'actions', width: 114, align: 'center', disabled: true,
    },
]
