<template>
    <div class="pl-5 w-full snap-end">
        <div class="max-w-min mr-4 flex">
            <div
                class="flex gap-2"
                v-for="(item, index) in breadCrumbsStore.items"
                :key="index"
            >
                <ArrowChevronRightIcon
                    v-if="index"
                    class="text-gray-370 dark:text-white"
                />

                <div
                    v-if="BCLength === (index + 1)"
                    class="text-xs text-gray-200 whitespace-nowrap"
                >
                    {{ item.title }}
                </div>

                <div
                    v-else
                    class="text-xs text-gray-370 dark:text-white cursor-pointer whitespace-nowrap"
                    @click="backAction(item, index)"
                >
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import ArrowChevronRightIcon from '@/components/icons/arrow/ArrowChevronRightIcon.vue'

import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'

const router = useRouter()

const breadCrumbsStore = useBreadCrumbsStore()

const BCLength = computed(() => breadCrumbsStore.items.length)

function backAction(item, index) {
    if (item.hasOwnProperty('routeName')) {
        router.push({ name: item.routeName })
    } else {
        breadCrumbsStore.goBackByIndex(index)
    }
}
</script>
