import { ROUTES } from '@/constants/routes'

export default function isNotEmptyWorkspace({ next, router, store }) {
    const { workspaces = {}, getWorkspaceSlug } = store

    if (workspaces.length) {
        return router.push({ name: ROUTES.reporting, params: { workspace: getWorkspaceSlug } })
    }

    next()
}
