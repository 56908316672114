function getTippyTable(content, maxWidth = 160) {
    return {
        content,
        theme: 'table',
        placement: 'bottom',
        maxWidth,
    }
}

function getTippySidebar(content) {
    return {
        content,
        placement: 'right',
        theme: 'menu',
    }
}

function getTippyAlert(content) {
    return {
        content,
        placement: 'right',
        theme: 'alert',
    }
}

function getTippyNumber(content) {
    return {
        content,
        theme: 'table',
        placement: 'bottom',
        followCursor: true,
    }
}

function getTippyLeft(content, maxWidth) {
    return {
        content,
        theme: 'table',
        placement: 'left',
        maxWidth,
    }
}

export {
    getTippyTable,
    getTippySidebar,
    getTippyNumber,
    getTippyAlert,
    getTippyLeft,
}
