import { useAuthorizationStore } from '@/store/authorizationStore'

function getSorting() {
    const authorizationStore = useAuthorizationStore()

    const sorting = localStorage.getItem('sorting')
    return sorting ? JSON.parse(sorting) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function getOnPage() {
    const authorizationStore = useAuthorizationStore()

    const sorting = localStorage.getItem('onPage')
    return sorting ? JSON.parse(sorting) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function setOnPage(onPage) {
    localStorage.setItem('onPage', onPage)
}

function setSorting(sorting) {
    localStorage.setItem('sorting', sorting)
}

function removeSorting() {
    localStorage.removeItem('sorting')
}

function getSortingInLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const sorting = getSorting()[authorizationStore.getWorkspaceSlug]

    if (sorting && sorting[type]) {
        return sorting[type]
    }

    return {}
}

function setSortingInLocalStorageByWorkspaceId(sorting, type) {
    const authorizationStore = useAuthorizationStore()

    let activeSorting = getSorting()

    if (activeSorting[authorizationStore.getWorkspaceSlug]) {
        activeSorting[authorizationStore.getWorkspaceSlug][type] = sorting
    } else {
        activeSorting = {
            ...activeSorting,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: sorting,
            },
        }
    }

    setSorting(JSON.stringify(activeSorting))
}

function setFilterOnPageInLocalStorageByWorkspaceId(onPage, type) {
    const authorizationStore = useAuthorizationStore()

    let activeOnPage = getOnPage()

    if (activeOnPage[authorizationStore.getWorkspaceSlug]) {
        activeOnPage[authorizationStore.getWorkspaceSlug][type] = onPage
    } else {
        activeOnPage = {
            ...activeOnPage,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: onPage,
            },
        }
    }

    setOnPage(JSON.stringify(activeOnPage))
}

function getFilterOnPageInLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const onPage = getOnPage()[authorizationStore.getWorkspaceSlug]

    if (onPage && onPage[type]) {
        return onPage[type]
    }

    return null
}

export {
    getSortingInLocalStorageByWorkspaceId,
    setSortingInLocalStorageByWorkspaceId,
    setFilterOnPageInLocalStorageByWorkspaceId,
    getFilterOnPageInLocalStorageByWorkspaceId,
    removeSorting,
}
