import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getAccountId = () => useAuthorizationStore().getAccountSlug

const getAllWorkspaces = () => axios.get(`${getAccountId()}/workspaces/all`)
const getWorkspaces = (params) => axios.get(`${getAccountId()}/workspaces/paginated`, { params })
const getDetailWorkspace = (slug) => axios.get(`${getAccountId()}/workspaces/${slug}`)
const createWorkspace = (formData) => axios.post(`${getAccountId()}/workspaces`, formData)
const updateWorkspace = ({ formData, slug }) => axios.patch(`${getAccountId()}/workspaces/${slug}`, formData)
const deleteWorkspace = (slug) => axios.delete(`${getAccountId()}/workspaces/${slug}`)

const addToFavorite = (slug) => axios.patch(`workspaces/${slug}/favorite`)
const removeFromFavorite = (slug) => axios.delete(`workspaces/${slug}/favorite`)

export {
    getAllWorkspaces, getWorkspaces, getDetailWorkspace, createWorkspace, updateWorkspace, deleteWorkspace, addToFavorite, removeFromFavorite,
}
