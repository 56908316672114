const number = {
    name: 'Number',
    value: 'number',
    sortSettings: {
        key: '',
        sortBy: 'byNumber',
    },
    filterSettings: {},
    disabled: true,
    activeDefault: true,
}

const name = {
    name: 'Name',
    value: 'name',
    sortSettings: {
        key: '',
        sortBy: 'byName',
    },
    filterSettings: {},
    activeDefault: true,
}

const country = {
    name: 'Country',
    value: 'country',
    sortSettings: {
        key: '',
        sortBy: 'byCountry',
    },
    filterSettings: {},
}

const type = {
    name: 'Type',
    value: 'type',
    sortSettings: {
        key: '',
        sortBy: 'byType',
    },
    filterSettings: {},
}

const region = {
    name: 'Region',
    value: 'region',
    sortSettings: {
        key: '',
        sortBy: 'byRegion',
    },
    filterSettings: {},
}

const allocated = {
    name: 'Allocated',
    value: 'allocated',
    // sortSettings: {
    //     key: '',
    //     sortBy: 'byAllocated',
    // },
    filterSettings: {},
}

const renew = {
    name: 'Renew',
    value: 'last_charge',
}

const status = {
    name: 'Status',
    value: 'status',
    align: 'center',
    width: 100,
    sortSettings: {
        key: '',
        sortBy: 'byStatus',
    },
    filterSettings: {},
    activeDefault: true,
}

export const MANAGE_NUMBER_FOR_MANAGER_HEADERS = [
    number,
    name,
    country,
    type,
    region,
    {
        name: 'Campaign',
        value: 'campaign',
        sortSettings: {
            key: '',
            sortBy: 'byCampaign',
        },
        filterSettings: {},
        activeDefault: true,
    },
    allocated,
    renew,
    {
        name: 'Vendor',
        value: 'vendor',
        sortSettings: {
            key: '',
            sortBy: 'byVendor',
        },
        filterSettings: {},
    },
    {
        name: 'Live',
        value: 'live',
        align: 'center',
        activeDefault: true,
    },
    {
        name: 'Hourly',
        value: 'hourly_revenue_cap',
        align: 'center',
        activeDefault: true,
    },
    {
        name: 'Daily',
        value: 'daily_revenue_cap',
        align: 'center',
        activeDefault: true,
    },
    {
        name: 'Monthly',
        value: 'monthly_revenue_cap',
        align: 'center',
        activeDefault: true,
    },
    {
        name: 'Global',
        value: 'global_revenue_cap',
        align: 'center',
        activeDefault: true,
    },
    status,
    {
        name: 'Actions',
        value: 'actions',
        width: 114,
        align: 'center',
        disabled: true,
        activeDefault: true,
    },
]

export const MANAGE_NUMBER_FOR_VENDOR_HEADERS = [
    number,
    name,
    country,
    type,
    region,
    allocated,
    renew,
    status,
]
