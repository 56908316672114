<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.0384 4.95629L19.1137 4.94019L21.2633 1.25966L23.0384 4.95629Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="8.60284" cy="14.3025" r="7.69708" stroke="currentColor" stroke-width="1.5"/>
        <path d="M5.74756 15.6543L5.74756 18.3091" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.60303 13.0098L8.60303 18.3008" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.4585 10.2988L11.4585 18.3056" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.0825 15.8422V5.05762" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
