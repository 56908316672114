import { setUserDataToCarrotquest } from '@/helpers/carrot-quest-helper'

function getAccessToken() {
    return localStorage.getItem('access_token')
}

function setAccessToken(token) {
    return localStorage.setItem('access_token', token)
}

function removeAccessToken() {
    setUserDataToCarrotquest([
        { op: 'update_or_create', key: 'Logged in', value: false },
    ])

    return localStorage.removeItem('access_token')
}

export {
    getAccessToken, setAccessToken, removeAccessToken,
}
