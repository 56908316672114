import { useRouter } from 'vue-router'
import { removeSorting } from '@/helpers/save-sorting-local-storage-helper'
import { removeFilter } from '@/helpers/save-filter-local-storage-helper'
import { removeFilterReporting } from '@/helpers/save-reporting-filter-local-storage-helper'
import { removeRangeReporting } from '@/helpers/save-range-local-storage-helper'
import { removeHeaderTableSettings } from '@/helpers/settings-header-table-helper'

function getClearQueryLocalStorage() {
    const value = localStorage.getItem('clear_query_local_storage_2')

    const isRemove = value ? JSON.parse(value) : false

    if (!isRemove) {
        const router = useRouter()
        router.replace().catch(() => {})
        removeSorting()
        removeFilter()
        removeFilterReporting()
        removeRangeReporting()
        removeHeaderTableSettings()
        setClearQueryLocalStorage(true)
        return false
    }

    return true
}

function setClearQueryLocalStorage(value) {
    localStorage.setItem('clear_query_local_storage_2', JSON.stringify(value))
}

export {
    getClearQueryLocalStorage,
    setClearQueryLocalStorage,
}
