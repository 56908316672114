import { useAuthorizationStore } from '@/store/authorizationStore'

function getRangeReporting() {
    const authorizationStore = useAuthorizationStore()

    const filter = localStorage.getItem('range')
    return filter ? JSON.parse(filter) : { [authorizationStore.getWorkspaceSlug]: {} }
}

function setRangeReporting(filter) {
    localStorage.setItem('range', filter)
}

function removeRangeReporting() {
    localStorage.removeItem('range')
}

function getRangeLocalStorageByWorkspaceId(type) {
    const authorizationStore = useAuthorizationStore()

    const filters = getRangeReporting()[authorizationStore.getWorkspaceSlug]

    if (filters && filters[type]) {
        return filters[type]
    }

    return {}
}

function setRangeInLocalStorageByWorkspaceId(filter, type) {
    const authorizationStore = useAuthorizationStore()

    let activeFilter = getRangeReporting()

    if (activeFilter[authorizationStore.getWorkspaceSlug]) {
        activeFilter[authorizationStore.getWorkspaceSlug][type] = filter
    } else {
        activeFilter = {
            ...activeFilter,
            [authorizationStore.getWorkspaceSlug]: {
                [type]: filter,
            },
        }
    }

    setRangeReporting(JSON.stringify(activeFilter))
}

export {
    getRangeLocalStorageByWorkspaceId,
    setRangeInLocalStorageByWorkspaceId,
    removeRangeReporting,
}
