import { defineStore } from 'pinia'

import { ROUTER_PARAMS_NAMES } from '@/constants/routerParamsNames'
import { useAuthorizationStore } from '@/store/authorizationStore'

export const useRouterStore = defineStore('routerStore', {
    state: () => ({}),

    actions: {
        getParams(paramsNames) {
            if (!paramsNames && !Array.isArray(paramsNames)) {
                return {}
            }

            const authorizationStore = useAuthorizationStore()

            return paramsNames.reduce((params, name) => {
                if (name === ROUTER_PARAMS_NAMES.workspace) {
                    params[name] = authorizationStore.getWorkspaceSlug
                }

                if (name === ROUTER_PARAMS_NAMES.account) {
                    params[name] = authorizationStore.getAccountSlug
                }

                return params
            }, {})
        },
    },
})
