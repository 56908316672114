<template>
    <CustomDialog
        v-model="show"
        hideHeader
        hideFooter
        hideCloseIcon
        width="max-mb:w-full min-mb:w-2/5"
        height="h-auto max-mb:h-full"
        border="max-mb:border-none max-mb:rounded-none border border-gray-100 dark:border-blue-720"
        position="items-start min-mb:pt-10"
        backgroundContainer="bg-black-900/40 dark:bg-black-900/70"
    >
       <template #withoutScroll>
           <div class="w-full max-mb:max-h-full">
               <div class="flex items-center py-2.5 pl-2.5 pr-4.5 relative border-b-1 border-gray-100 dark:border-gray-700">
                   <InputSearch
                       v-model:searchValue="searchValue"
                       autoFocus
                       isBig
                       customClass="pb-0"
                       placeholder="What are you looking for?"
                       @onSearch="onSearch"
                   />

                   <CloseIconMini
                       class="cursor-pointer text-gray-200"
                       @click.stop="closeDialog"
                   />

                   <ErrorBlock
                       :message="message"
                       position="bottom-0 left-5"
                   />
               </div>

               <div class="my-1.25 max-mb:h-full max-mb:pb-17.5">
                   <div
                       v-if="loading"
                       class="flex justify-center items-center mx-2.5 py-1.75"
                   >
                       <PreLoader class="text-gray-200" />
                   </div>

                   <div
                       v-else-if="isShowText"
                       class="mx-2.5 py-3.5 px-2.5 text-xxxs text-gray-280 select-none"
                   >
                       {{ text }}
                   </div>

                   <GlobalSearchList
                       v-else
                       :items="items"
                       @closeGlobalSearch="closeGlobalSearch"
                   />
               </div>
           </div>
       </template>
    </CustomDialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import CustomDialog from '@/components/dialogs/CustomDialog.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import CloseIconMini from '@/components/icons/CloseIconMini.vue'
import PreLoader from '@/components/icons/preloaders/PreLoader.vue'
import GlobalSearchList from '@/components/lists/GlobalSearchList.vue'
import ErrorBlock from '@/components/errorBlock/ErrorBlock.vue'

import { getGlobalSearch } from '@/api/global-search-routes'

import { useDefaultStore } from '@/store/defaultStore'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:modelValue'])

const defaultStore = useDefaultStore()

const loading = ref(false)
const message = ref(null)

const searchValue = ref('')
const items = ref([])

const show = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

const text = computed(() => (searchValue.value ? 'No results found matching your search query.' : 'Try to tip something like +1234'))

const isShowText = computed(() => !items.value.length)

function closeDialog() {
    show.value = false
}

function onSearch(value) {
    searchValue.value = value

    checkValidation()
}

function closeGlobalSearch() {
    closeDialog()
}

function setMessage() {
    message.value = 'Value must be longer than 3 characters'
}

function resetMessage() {
    message.value = null
}

function checkValidation() {
    resetMessage()
    if (!searchValue.value) {
        clearItemsSearch()
        return
    }

    if (searchValue.value.length < 3) {
        setMessage()
        return
    }

    loadGlobalSearch()
}

async function loadGlobalSearch() {
    loading.value = true

    const { success, message, payload } = await getGlobalSearch({
        query: searchValue.value,
    })

    if (success) {
        items.value = payload
        defaultStore.setSuccessMessage({ message })
    } else {
        defaultStore.setErrorMessage({ message })
    }

    loading.value = false
}

function clearSearch(show) {
    if (!show) {
        searchValue.value = ''
        clearItemsSearch()
    }
}

function clearItemsSearch() {
    items.value = []
}

watch(show, clearSearch)
</script>
