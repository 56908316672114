export const THEMES = {
    auto: {
        name: 'Use system settings',
        id: 'auto',
    },
    white: {
        name: 'Light',
        id: 'white',
    },
    dark: {
        name: 'Dark',
        id: 'dark',
    },
}
