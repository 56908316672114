<template>
    <CustomMenu
        v-model="isOpenPopup"
        hideTransitionSlider
    >
        <template #activator>
            <div
                class="flex items-center gap-x-3.5 px-3 cursor-pointer"
                :class="[ mini && 'justify-center items-center' ]"
                @click="onOpenCloseAccountMenu"
            >
                <UserImage :image="currentUser.image" />

                <div v-if="!mini">
                    <div class="mb-1 max-w-33.75 text-xs text-gray-370 dark:text-white truncate">
                        {{ userName }}
                    </div>

                    <div class="text-xxxs max-w-33.75 text-gray-200 dark:text-gray-250 truncate">
                        {{ currentUser.email }}
                    </div>
                </div>
            </div>
        </template>

        <template #dropdown>
            <div
                class="w-53.25 absolute left-14 -bottom-11 rounded-1.25 bg-white dark:bg-blue-800 border border-white-500 dark:border-gray-760 shadow-activeMenu dark:shadow-activeMenuDark"
                @click.stop
            >
                <div class="flex items-center gap-x-3.5 pt-2.5 pb-3.5 px-3.75 border-b-1 border-white-500 dark:border-gray-760">
                    <UserImage :image="currentUser.image" />

                    <div>
                        <div class="mb-1 max-w-33.75 truncate text-xs text-gray-370 dark:text-white">
                            {{ userName }}
                        </div>

                        <div class="max-w-33.75 truncate text-xxxs text-gray-250 dark:text-gray-250">
                            {{ currentUser.email }}
                        </div>
                    </div>
                </div>

                <div class="mx-0.5 my-0.5 grid gap-y-1">
                    <template
                        v-for="route in menu"
                        :key="route.id"
                    >
                        <CustomRouterLink
                            :class="menuStyle(route)"
                            :style="customStyle(route)"
                            :route="route"
                            :simpleRote="!route.subMenu && !!route.routeName"
                            @click.stop="onOpenSubMenu(route)"
                        >
                            <div
                                class="flex items-center gap-x-3 group"
                                :class="[route.isOpenSub && 'mb-3.25']"
                            >
                                <div class="w-6">
                                    <component :is="route.icon()" />
                                </div>

                               <div class="text-3.25">
                                   {{ route.name }}
                               </div>

                                <div
                                    v-if="route.subMenu"
                                    class="w-6 h-6 flex items-center justify-center ml-auto"
                                >
                                    <ArrowSelect
                                        class="w-3 transform duration-100"
                                        :class="{ 'rotate-180': route.isOpenSub }"
                                    />
                                </div>
                            </div>

                            <RoutingSideBarBig
                                :route="route"
                                :checkCurrentLink="checkCurrentLink"
                                wrapperSubMenu="ml-3"
                                @onLinkCLick="onOpenCloseAccountMenu"
                            />
                        </CustomRouterLink>
                    </template>
                </div>

                <div class="flex items-center gap-x-2.5 px-3 py-3.5 border-t-1 border-white-500 dark:border-gray-760">
                    <ThemeToggle v-model:currentTheme="currentTheme"/>

                    <div class="text-xs text-gray-200">
                        {{ currentTheme }} theme
                    </div>
                </div>
            </div>
        </template>
    </CustomMenu>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import CustomMenu from '@/components/menu/CustomMenu/CustomMenu.vue'
import CustomRouterLink from '@/components/navigation/CustomRouterLink.vue'
import ArrowSelect from '@/components/icons/ArrowSelect.vue'
import RoutingSideBarBig from '@/components/navigation/RoutingSideBar/RoutingSideBarBig.vue'
import UserImage from '@/components/userImage/userImage.vue'
import ThemeToggle from '@/components/buttons/ThemeToggle.vue'

import { SETTINGS_MENU_ROUTES, SETTINGS_MENU_ROUTES_OWNER } from '@/constants/settingsMenu'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'
import { useAuthorizationStore } from '@/store/authorizationStore'

defineProps({
    mini: {
        type: Boolean,
        default: false,
    },
})

const authorizationStore = useAuthorizationStore()

const route = useRoute()

const currentTheme = ref('')

const isOpenPopup = ref(false)
const menu = ref([])

const currentUser = computed(() => authorizationStore.user || {})
const isOwner = computed(() => authorizationStore.isOwner)

const userName = computed(() => {
    const { first_name, last_name } = currentUser.value

    return `${first_name} ${last_name}`
})

onMounted(() => {
    setDefaultMenu()
})

function menuStyle(route) {
    const { isOpenSub, subMenu } = route

    const active = checkCurrentLink(route, subMenu)

    const classes = ['text-3.25 px-3 py-1.5 rounded-1.25 text-xs transition-all cursor-pointer relative']

    if (!subMenu) {
        classes.push('flex')
    }

    if (isOpenSub) {
        classes.push(
            'dark:hover:bg-transparent space-y-1',
            active ? 'text-gray-370 dark:text-white' : 'text-gray-370 dark:text-white',
        )
    } else {
        classes.push(
            'hover:bg-menu-purple70 dark:hover:bg-menu-blue770',
            active ? 'border-purple dark:border-transparent text-purple dark:text-purple-140' : 'border-transparent text-gray-370 dark:text-white',
        )
    }

    return classes.join(' ')
}

function customStyle({ isOpenSub, subMenu }) {
    if (subMenu) return { height: isOpenSub ? `${50 + (32 * subMenu.length) + (4 * (subMenu.length - 1))}px` : '34px' }
}

function onOpenCloseAccountMenu() {
    isOpenPopup.value = !isOpenPopup.value
}

function onOpenSubMenu(route) {
    const { isOpenSub, subMenu } = route

    if (subMenu) {
        route.isOpenSub = !isOpenSub
    } else {
        onOpenCloseAccountMenu()
    }

    const foundElement = menu.value.find((el) => el.name !== route.name && el.isOpenSub)

    if (foundElement) {
        foundElement.isOpenSub = false
    }

    navigationActionHandler(route)
}

function checkCurrentLink(parent, subMenu) {
    const { routeName, isFirstLoad } = parent

    if (subMenu) {
        const isActive = subMenu.some((el) => el.routeName === route.name)
        if (isActive && isFirstLoad) {
            parent.isOpenSub = isActive
            parent.isFirstLoad = false
        }
        return isActive
    }
    return route.name === routeName
}

function navigationActionHandler(route) {
    if (!route.hasOwnProperty('action')) {
        return
    }

    switch (route.action) {
    case NAVIGATION_ACTIONS.logout:
        logout()
    }
}

function logout() {
    authorizationStore.logout()
}

function setDefaultMenu() {
    if (isOwner.value) {
        menu.value = SETTINGS_MENU_ROUTES_OWNER
    } else {
        menu.value = SETTINGS_MENU_ROUTES
    }
}
</script>
