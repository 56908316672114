import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getAllNumbers = () => axios.get(`${getWorkspaceId()}/numbers/all`)
const getNumbersForManager = (params) => axios.get(`${getWorkspaceId()}/numbers/paginated`, { params })
const getNumbersForVendor = (params) => axios.get(`${getWorkspaceId()}/vendor/numbers/paginated`, { params })
const getSearchNumbers = (params) => axios.get(`${getWorkspaceId()}/numbers/search`, { params })
const getSearchNumbersForVendor = (params) => axios.get(`${getWorkspaceId()}/vendor/numbers/search`, { params })
const getDetailNumber = (slug) => axios.get(`${getWorkspaceId()}/numbers/${slug}`)
const buyNumber = (formData) => axios.post(`${getWorkspaceId()}/numbers`, formData)
const buyNumberForVendor = (formData) => axios.post(`${getWorkspaceId()}/vendor/numbers`, formData)
const deleteNumber = (data) => axios.delete(`${getWorkspaceId()}/numbers`, { data })
const updateNumber = ({ formData, slug }) => axios.patch(`${getWorkspaceId()}/numbers/${slug}`, formData)
const updateNumbersStatus = (formData) => axios.patch(`${getWorkspaceId()}/numbers/status`, formData)
const getNumbersCounters = (formData) => axios.post(`${getWorkspaceId()}/numbers/counters`, formData)
const resetNumberCaps = (slug) => axios.delete(`${getWorkspaceId()}/numbers/${slug}/counters`)

const getNumberFiltersAndSorting = () => axios.get(`${getWorkspaceId()}/numbers/filters-and-sorting`)

export {
    getAllNumbers,
    getNumbersForManager,
    getNumbersForVendor,
    getSearchNumbers,
    getSearchNumbersForVendor,
    getDetailNumber,
    buyNumber,
    buyNumberForVendor,
    deleteNumber,
    updateNumber,
    updateNumbersStatus,
    getNumbersCounters,
    resetNumberCaps,
    getNumberFiltersAndSorting,
}
