<template>
    <router-link
        v-if="simpleRote"
        v-bind="$attrs"
        :to="to"
        :key="to.name"
        v-tippy="getTippyContent()"
    >
        <slot></slot>
    </router-link>

    <div v-else-if="route.isBusinessPhone">
        <tippy class="w-full" theme="menu" placement="right" :maxWidth="290" touch>
            <template #default>
                <div class="flex items-center">
                    <slot></slot>
                </div>
            </template>

            <template #content>
                <div class="mb-1">{{ route.name }}</div>
                <div>{{ route.tippy }}</div>
            </template>
        </tippy>
    </div>

    <div
        v-else
        v-bind="$attrs"
        v-tippy="getTippyContent()"
    >
        <slot></slot>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { Tippy } from 'vue-tippy'
import { ROUTER_PARAMS_NAMES } from '@/constants/routerParamsNames'
import { useAuthorizationStore } from '@/store/authorizationStore'

import { getTippySidebar } from '@/helpers/tippy-helper'

const props = defineProps({
    simpleRote: {
        type: Boolean,
        default: true,
    },
    mini: {
        type: Boolean,
        default: false,
    },
    route: {
        type: Object,
        required: true,
    },
    classes: {
        type: String,
        default: '',
    },
    styles: {
        type: Object,
        default: () => ({}),
    },
})

const authorizationStore = useAuthorizationStore()

const to = computed(() => ({ name: props.route.routeName, params: getParams(props.route.paramsNames) }))

function getParams(paramsNames) {
    if (!paramsNames && !Array.isArray(paramsNames)) {
        return {}
    }

    return paramsNames.reduce((params, name) => {
        if (name === ROUTER_PARAMS_NAMES.workspace) {
            params[name] = authorizationStore.getWorkspaceSlug
        }

        if (name === ROUTER_PARAMS_NAMES.account) {
            params[name] = authorizationStore.getAccountSlug
        }

        return params
    }, {})
}

function getTippyContent() {
    if (!props.mini) {
        return ''
    }

    return getTippySidebar(props.route.tippy ? props.route.tippy : props.route.name)
}
</script>
