import { defineStore } from 'pinia'

import {
    checkWhiteLabel,
    deleteWhiteLabel,
    getWhiteLabel,
    verifyCertificateIssued,
    issueCertificateWhiteLabel,
    updateWhiteLabel,
    verifyCertificateSet,
    verifyDistributionSet,
    createDistribution,
} from '@/api/white-label'

import { useDefaultStore } from '@/store/defaultStore'

import {
    changeColorsProperty, changeBorderRadius, changeAppTitle, changeAppFavicons,
} from '@/helpers/whitelablel-helper'

export const useWhiteLabelStore = defineStore('whiteLabel', {
    state: () => ({
        formData: {
            company_name: '',
            fav_icon: '',
            logo_icon: '',
            email_icon: '',

            primary: '#6F4FCC',
            hover: '#6F4FCC',

            dark_highlight_color: '#2D2E34',
            light_highlight_color: '#E5E2EA',

            dark_menu_bg_color: '#212228',
            light_menu_bg_color: '#F7F7FA',

            dark_buttons_active_color: '#2C2B3D',
            light_buttons_active_color: '#F6F3FC',

            dark_buttons_bg_color: '#1E1F25',
            light_buttons_bg_color: '#EAE5F8',

            border_radius: 5,

            domain: '',

            certificate: {
                certificate_validation_value: '',
                certificate_validation_name: '',
                validation_started: false,
                cert_name_is_set: false,
                certificate_validated: false,
            },

            distribution: {
                distribution_domain: '',
                deployment_started: false,
                distribution_cname_is_set: false,
                distribution_deployed: false,
            },
        },
        confirmLoading: false,
        whiteLabelChecked: false,
        certificateVerifyLoading: false,
        whiteLabelData: {},
    }),
    getters: {
        whiteLabelIsEnabled(state) {
            return !!Object.keys(state.whiteLabelData).length
        },
    },
    actions: {
        async getCheckWhiteLabel() {
            if (this.whiteLabelChecked) {
                return
            }

            this.whiteLabelChecked = true

            const { success, payload } = await checkWhiteLabel()

            if (success) {
                this.whiteLabelData = payload

                this.changeWhitelabelStyles()
            }
        },

        async updateWhiteLabel(updateData) {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await updateWhiteLabel({
                ...this.formData,
                ...updateData,
            })

            if (success) {
                this.parseData(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async verifyCertificateIsSet() {
            this.certificateVerifyLoading = true

            const defaultStore = useDefaultStore()

            const { success, payload, message } = await verifyCertificateSet()

            if (success) {
                this.parseData(payload)

                if (this.formData.certificate.certificate_validated) {
                    defaultStore.setSuccessMessage({ message })
                }
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.certificateVerifyLoading = false
        },

        async createDistribution() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await createDistribution()

            if (success) {
                this.parseData(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async verifyDistributionSet() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await verifyDistributionSet()

            if (success) {
                if (!this.formData.distribution.deployment_started || payload.distribution.distribution_deployed) {
                    defaultStore.setSuccessMessage({ message })
                }

                this.parseData(payload)
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async goToLoadWhiteLabel() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await getWhiteLabel()

            if (success) {
                this.parseData(payload)

                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },

        async deleteWhiteLabel(showMessage = true) {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await deleteWhiteLabel()

            if (success) {
                this.whiteLabelData = {}

                this.parseData(payload)

                if (showMessage) {
                    defaultStore.setSuccessMessage({ message })
                }
            } else {
                defaultStore.setErrorMessage({ message })
            }

            return { success }
        },

        async confirmDomain(domain) {
            const defaultStore = useDefaultStore()

            const { success, message } = await updateWhiteLabel({ ...this.formData, domain })

            if (success) {
                await this.issueCertificateWhiteLabel()
            } else {
                this.changeConfirmLoading(false)

                defaultStore.setErrorMessage({ message })

                await this.deleteWhiteLabel(false)
            }
        },

        changeConfirmLoading(loading) {
            this.confirmLoading = loading
        },

        async issueCertificateWhiteLabel() {
            const defaultStore = useDefaultStore()

            const { success, message } = await issueCertificateWhiteLabel()

            if (success) {
                await this.verifyCertificateIssued()
            } else {
                this.changeConfirmLoading(false)

                defaultStore.setErrorMessage({ message })

                await this.deleteWhiteLabel(false)
            }
        },

        async verifyCertificateIssued() {
            const defaultStore = useDefaultStore()

            const { success, payload, message } = await verifyCertificateIssued()

            if (success) {
                this.parseData(payload)

                if (payload.certificate.certificate_validation_name && payload.certificate.certificate_validation_value) {
                    defaultStore.setSuccessMessage({ message })
                }
            } else {
                defaultStore.setErrorMessage({ message })

                await this.deleteWhiteLabel(false)
            }

            this.changeConfirmLoading(false)
        },

        parseData(payload) {
            Object.keys(payload).forEach((name) => {
                this.formData[name] = payload[name]
            })

            if (this.whiteLabelData.domain !== payload.domain) {
                return
            }

            Object.keys(this.whiteLabelData).forEach((name) => {
                this.whiteLabelData[name] = payload[name]
            })

            this.changeWhitelabelStyles()
        },

        changeWhitelabelStyles() {
            if (!this.whiteLabelIsEnabled) {
                return
            }

            changeColorsProperty(this.whiteLabelData)

            changeBorderRadius(this.whiteLabelData.border_radius)

            changeAppTitle(this.whiteLabelData.company_name)

            changeAppFavicons(this.whiteLabelData.fav_icon)
        },
    },
})
