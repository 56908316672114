import axios from '@/plugins/axios'

import { useAuthorizationStore } from '@/store/authorizationStore'

const getWorkspaceId = () => useAuthorizationStore().getWorkspaceSlug

const getGlobalSearch = (params) => axios.get(`${getWorkspaceId()}/global-search`, { params })

export {
    getGlobalSearch,
}
