import axios from '@/plugins/axios'

const getLogin = (formData) => axios.post('auth/login', formData)
const checkWorkspaceAccess = (slug) => axios.get(`auth/workspaces/${slug}`)
const checkUserAccess = () => axios.post('auth/check')
const getLogout = () => axios.post('auth/logout')
const getLogoutAll = () => axios.post('auth/logout-all')
const changePassword = (formData) => axios.patch('auth/password', formData)

const sendEmailForResetPassword = (params) => axios.get('auth/reset-password', { params })
const checkResetToken = (token) => axios.get(`auth/reset-password/${token}`)
const sendNewPassword = ({ token, formData }) => axios.post(`auth/reset-password/${token}`, formData)

const getAuth2faMethods = () => axios.get('auth/2fa/methods')
const sendEnableAuth2faMethod = (methodId) => axios.get(`auth/2fa/methods/${methodId}/enable`)
const sendEnable2faVerificationCode = ({ methodId, formData }) => axios.post(`auth/2fa/methods/${methodId}`, formData)
const sendDisable2faVerificationCode = ({ methodId, data }) => axios.delete(`auth/2fa/methods/${methodId}`, { data })

const getRecoveryCodes = () => axios.get('auth/2fa/recovery-codes')
const generateRecoveryCodes = () => axios.post('auth/2fa/recovery-codes')

export {
    getLogin,
    checkWorkspaceAccess,
    checkUserAccess,
    getLogout,
    getLogoutAll,
    sendEmailForResetPassword,
    checkResetToken,
    sendNewPassword,
    changePassword,
    getAuth2faMethods,
    sendEnableAuth2faMethod,
    sendEnable2faVerificationCode,
    sendDisable2faVerificationCode,
    getRecoveryCodes,
    generateRecoveryCodes,
}
