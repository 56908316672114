<template>
    <div
        v-if="image"
        class="relative flex justify-center items-center rounded-full overflow-hidden"
        :class="[
            big ? 'w-15 h-15' : 'w-8.5 h-8.5',
            { 'block-show': showRemoveBlock }
        ]"
    >
        <img
            class="w-full h-full select-none object-contain"
            :src="image"
        >

        <div
            v-if="showRemoveBlock"
            class="block-hide h-full w-full flex items-center justify-center text-white inset-0 absolute bg-black/50 z-10"
            @click="onRemove"
        >
            <CloseIcon class="w-4 h-4" />
        </div>
    </div>

    <DefaultAvatarUser
        v-else
        :big="big"
    />
</template>

<script setup>

import DefaultAvatarUser from '@/components/defaultAvatars/DefaultAvatarUser.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

defineProps({
    big: {
        type: Boolean,
        default: false,
    },
    image: {
        type: String,
        default: '',
    },
    showRemoveBlock: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['onRemove'])

function onRemove() {
    emits('onRemove')
}
</script>
