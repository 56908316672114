export default function isAccountDisabled({ next, router, store }) {
    const { workspace = {}, getWorkspaceSlug } = store
    const { owner_blocked = false } = workspace

    if (!owner_blocked) {
        return router.push({ name: 'Reporting', params: { workspace: getWorkspaceSlug } })
    }

    next()
}
