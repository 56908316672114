import BillingIcon from '@/components/icons/navigations/BillingIcon.vue'
import SecurityIcon from '@/components/icons/navigations/SecurityIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'
import UserIcon from '@/components/icons/navigations/UserIcon.vue'
import AccountSettingsIcon from '@/components/icons/navigations/AccountSettingsIcon.vue'

import { ROUTES } from '@/constants/routes'
import { ROUTER_PARAMS_NAMES } from '@/constants/routerParamsNames'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'

const userSetting = {
    id: 1,
    name: 'Profile',
    routeName: ROUTES.userSetting,
    icon: () => UserIcon,
}

const logout = {
    id: 5,
    name: 'Logout',
    action: NAVIGATION_ACTIONS.logout,
    icon: () => LogoutIcon,
}

export const SETTINGS_MENU_ROUTES_OWNER = [
    userSetting,
    {
        id: 4,
        name: 'Account Settings',
        entity: 'settings',
        icon: () => AccountSettingsIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Account',
                routeName: ROUTES.accountSetting,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
            },
            {
                name: 'White Label',
                routeName: ROUTES.whiteLabel,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
            },
        ],
    },
    {
        id: 5,
        name: 'Security',
        icon: () => SecurityIcon,
        isOpenSub: false,
        isFirstLoad: true,
        entity: 'security',
        subMenu: [
            {
                name: 'Password',
                routeName: ROUTES.password,
                entity: 'security',
            },
            {
                name: 'Login Security',
                routeName: ROUTES.loginSecurity,
                entity: 'security',
            },
            {
                name: 'Two-Factor Auth',
                routeName: ROUTES.twoFactorAuth,
                entity: 'security',
            },
            {
                name: 'Protection',
                routeName: ROUTES.protection,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'security',
            },
            {
                name: 'API-Token',
                routeName: ROUTES.apiAccessTokens,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'security',
            },
            {
                name: 'Workspace Activity',
                routeName: ROUTES.workspaceActivity,
                paramsNames: [ROUTER_PARAMS_NAMES.workspace],
                entity: 'security',
            },
        ],
    },
    {
        id: 6,
        name: 'Billing',
        icon: () => BillingIcon,
        isOpenSub: false,
        isFirstLoad: true,
        entity: 'billing',
        subMenu: [
            {
                name: 'Billing Profile',
                routeName: ROUTES.billingProfile,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'billing',
            },
            {
                name: 'Recharge Balance',
                routeName: ROUTES.rechargeBalance,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'billing',
            },
            {
                name: 'Manage Cards',
                routeName: ROUTES.manageCards,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'billing',
            },
            {
                name: 'Payment History',
                routeName: ROUTES.paymentHistory,
                paramsNames: [ROUTER_PARAMS_NAMES.account],
                entity: 'billing',
            },
        ],
    },
    logout,
]

export const SETTINGS_MENU_ROUTES = [
    userSetting,
    {
        id: 3,
        name: 'Security',
        icon: () => SecurityIcon,
        isOpenSub: false,
        isFirstLoad: true,
        entity: 'settings',
        subMenu: [
            {
                name: 'Password',
                routeName: ROUTES.password,
                entity: 'settings',
            },
            {
                name: 'Login Security',
                routeName: ROUTES.loginSecurity,
                entity: 'settings',
            },
            {
                name: 'Two-Factor Auth',
                routeName: ROUTES.twoFactorAuth,
                entity: 'settings',
            },
        ],
    },
    logout,
]
